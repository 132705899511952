import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Modal} from 'react-bootstrap';
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { request } from '../../services/Request';
import '../../styles/login.scss';
import '../../styles/page.scss';
import { ResponseResult } from '../../types';
import Swal from 'sweetalert2';
import { login } from '../../services/auth';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

interface FormData {
    email?: string,
    password?: string,
}

const Login = () => {
    const [formData,setFormData] = useState<FormData>({})
    const [showPassword,setShowPassword] = useState(false);
    const [showModal, setModal] = useState(false);

    useEffect(()=>{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if(urlParams.get('password')){
            Swal.fire('Usuario', "Su contraseña se ha cambiado con éxito. Ahora puede ingresar con su cuenta y nueva contraseña.", "success");
        }
    },[]);

    const handleClose = () => {
        setModal(false);
    }

    const tooglePassowrd = () => {
        setShowPassword(!showPassword)
    }

    const onSubmit = (e:React.SyntheticEvent) => {
        Swal.fire('Inicio de sesión', 'Cargando...');
        Swal.showLoading();
        e.preventDefault();
        request('/api/auth/signin',{
            method: 'POST',
            body: JSON.stringify(formData)
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                login(result.data);
                window.location.href = "/perfil";
            }else{
                Swal.fire("Ingresar", "Ocurrió un error al ingresar, revisa tu email y contraseña", 'error');
            }
        })
    }


    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let data : any = formData;
        data[e.target.name] = e.target.value;
        setFormData(data);
    }

    const sendEmail = () => {
        Swal.fire('Recuperar contraseña', 'Enviando petición...');
        Swal.showLoading();
        request('/api/auth/request',{
            method: 'POST',
            body: JSON.stringify(formData)
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                Swal.fire('Recuperar contraseña','Se ha enviado un correo a su cuenta para continuar con el proceso.','success');
            }else{
                Swal.fire('Recuperar contraseña', 'Ocurrió un error con la petición', 'error');
            }
        })
    }

    const responseFacebook = (response:any) => {
        Swal.fire("Ingresando...");
        Swal.showLoading();
        request('/api/auth/facebook',{
            method: 'POST',
            body: JSON.stringify(response)
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                login(result.data);
                window.location.href = "/perfil";
            }else{
                Swal.fire("Inicio de sesión Facebook", "Ocurrió un error al ingresar");
            }
        })
    }

    return (
        <Container fluid className="page login">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12}>
                            <h1>
                                Iniciar Sesión
                            </h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Row className="justify-content-md-center py-5">
                <Col md={6} sm={12} xs={12}>
                    <Form onSubmit={onSubmit}>
                        <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextEmail">
                            <Form.Label column sm="2" className="text-end">
                                Email
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="email" name="email" placeholder="" required onChange={onChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3 py-2" controlId="formPlaintextPassword">
                            <Form.Label column sm="2" className="text-end">
                                Contraseña
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    className="input-password"
                                    type={ showPassword ? `text` : `password`}
                                    placeholder=""
                                    name="password"
                                    required onChange={onChange}
                                    />
                                { showPassword && (
                                    <BsFillEyeSlashFill className="eye" onClick={tooglePassowrd} />
                                )}
                                { !showPassword && (
                                    <BsFillEyeFill className="eye" onClick={tooglePassowrd} />
                                )}
                            </Col>
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3 py-0" controlId="formbutton">
                                    <Col sm={{ span: 10, offset: 2 }}>
                                        <Button type="submit"
                                            className="btn-custom-pink btn-clock w-100 my-2">
                                            Ingresar
                                        </Button>
                                        <FacebookLogin
                                        appId={process.env.REACT_APP_FB_ID || ''}
                                        autoLoad={false}
                                        fields="name,email"
                                        callback={responseFacebook}
                                        render={(renderProps:any) => (
                                            <button type="button" onClick={renderProps.onClick} className="btn btn-custom-blue w-100 my-2">Iniciar sesión con Facebook</button>
                                          )}/>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group className="mb-3 py-0" controlId="formenlaces">
                                    <Col sm={{ span: 10, offset: 2 }}>
                                        <div className="col-sm-12 pt-3 text-center">
                                            <p><a href="/registrar">¿No eres miembro? Registrate</a></p>
                                            <p><Button variant="link" onClick={()=>{setModal(true)}}>Olvidé mi contraseña</Button></p>
                                        </div>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>

                </Col>
            </Row>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Recuperar contraseña</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Ingresa tu correo electrónico</Form.Label>
                        <Form.Control type="email" name="email" onChange={onChange}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={sendEmail}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}
export default Login;