import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import EditPencil from '../../assets/edit_pencil.svg';
import MapMarker from '../../assets/map_marker.svg';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Location } from '../../types';

interface IProps {
    address?: string;
    onSubmit: (e:React.SyntheticEvent,inputRef: React.RefObject<HTMLInputElement>,onSuccess:()=>void)=>void;
    location?: Location;
    onSave: (formData: FormData, onSuccess:()=>void)=>void;
}

const BusinessAddress = (props: IProps) => {

    const {
        address = '',
        onSubmit,
        onSave,
        location = {
            lat: 21.8842646,
            lng: -102.3027221
            }
        } = props;
    const [enableAddressEditForm,setEnableAddressEditForm] = useState(true);
    const inputAddressRef = useRef<HTMLInputElement>(null);

    useEffect(()=>{
        if(inputAddressRef.current){
            inputAddressRef.current.focus()
        }
    },[enableAddressEditForm])

    const containerStyle = {
        width: '100%',
        height: '600px',
    }

    // https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
    const mapOptions = {
        streetViewControl: false,
        styles: [
            {
                featureType: "poi.business",
                stylers: [{ visibility: "off" }]
            }
        ]
    }

    const center = {
        lat: 21.8842646,
        lng: -102.3027221
    }

    const hideAddressEditForm = () => {
        setEnableAddressEditForm(false);
    }
    const showAddressEditForm = () => {
        setEnableAddressEditForm(true);
    }

    const onDragEnd = (e: any) => {
        const { latLng } = e;
        const lat = latLng.lat();
        const lng = latLng.lng();
        const formData = new FormData();
        formData.append(
            "location",
            JSON.stringify({lat,lng}),
        );
        formData.append(
            "field",
            "location",
        );
        onSave(formData,()=>{});
    }

    return (
        <Container className="business-profile-address mt-5 pt-5">
            <Row>
                <Col>
                    <div className="fs-2 text-center">Dirección y Ubicación</div>
                </Col>
            </Row>
            <Row className="py-5">
                <Col>
                { !enableAddressEditForm && (
                    <div>
                        <div className="address-field fs-3 fw-light d-inline">
                            {address && address !== '' ? address : 'Ingresa la dirección de tu negocio'}
                        </div>
                        <div className="edit-pencil d-inline">
                            <img src={EditPencil} alt="Cambiar nombre" onClick={showAddressEditForm} />
                        </div>
                    </div>
                )}
                { enableAddressEditForm && (
                    <div className="w-100">
                        <Form onSubmit={(e)=>{onSubmit(e,inputAddressRef,hideAddressEditForm);}} >
                            <input type="text" placeholder="Ingresa la dirección del negocio " name="address" ref={inputAddressRef} className="fs-2 fw-light w-100" defaultValue={address} />
                            <div>
                                <Button type="submit" className="btn-custom-pink">Guardar</Button>
                                <Button onClick={hideAddressEditForm} className="btn-custom-pink-outline-light">Cancelar</Button>
                            </div>
                        </Form>
                    </div>
                )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="fs-5 text-center">Arrastre el marcador a donde se ubica su negocio</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <LoadScript
                        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
                    >
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={16}
                            options={mapOptions}
                                >
                            { /* Child components, such as markers, info windows, etc. */ }
                            <></>
                            <Marker
                                icon={MapMarker}
                                position={location}
                                draggable={true}
                                animation={2}
                                onDragEnd={onDragEnd}
                                />
                        </GoogleMap>
                    </LoadScript>
                </Col>
            </Row>
        </Container>
    )
}
export default BusinessAddress;