import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Business } from '../types';
import { request } from '../services/Request';
import { ResponseResult } from '../types';

// Define a type for the slice state
export interface BusinessState {
    data: Business[],
    loaded: boolean,
    error: string
  }

  // Define the initial state using that type
  const initialState: BusinessState = {
    data: [],
    loaded: false,
    error: ''
  }

export const getBusinesses = createAsyncThunk(
  'businesses/getBusinesses',
  async () => {
    const response : ResponseResult = await request('/api/businesses',{});
    return [...response.data].sort(() => 0.5 - Math.random())
    //return response.data;
  }
)

export const businessesSlice = createSlice({
    name: 'businesses',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder.addCase(getBusinesses.pending, (state) => {
        state.loaded = false;
      });
      builder.addCase(getBusinesses.fulfilled, (state, { payload }) => {
        state.loaded = true;
        state.data = payload;
      });
      builder.addCase(getBusinesses.rejected, (state, action) => {
        state.loaded = false;
        if(action.payload){
          state.error = 'failed';
        } else {
          state.error = action.error.message || '';
        }
      });
    },
  })

  export default businessesSlice.reducer