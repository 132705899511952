import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import '../../styles/page.scss';
import { Business, ResponseResult } from '../../types';
import Swal from 'sweetalert2';
import {request} from '../../services/Request';

const Businesses = () => {

    const businesses = useSelector((state: RootState) =>  state.businesses);
    const [businessItems, setBusinessItems] = useState<Business[]>([]);

    useEffect(()=>{
        if(businesses.data && businesses.data.length > 0){
            sortBy('name');
        }
    },[businesses]);

    const sortBy = (field:'name'|'created_at') => {
        const items = sortByProperty( JSON.parse(JSON.stringify(businesses.data)), field);
        setBusinessItems(items);
    }

    const removeBusiness = (item:Business) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar el negocio '+ item.name +' ?',
            text: "No podrás revertir este cambio.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar!',
            cancelButtonText: 'No'
        }).then((result:any) => {
            if (result.isConfirmed) {
                request(`/api/businesses/${item._id}`,{
                    method: 'DELETE'
                })
                .then((result:ResponseResult)=>{
                    if(result.data){
                        window.location.reload();
                    }else{
                        Swal.fire('Negocios', "No se pudo eliminar el negocio", "error");
                    }
                })
            }
        })
    }

    function sortByProperty(array: Business[], propertyName:'name'|'created_at') {
        return array.sort(function (a:Business, b:Business) {
            if(a[propertyName] < b[propertyName]) { return -1; }
            if(a[propertyName] > b[propertyName]) { return 1; }
            return 0
        });
    }

    const resendInvitation = (item:Business) => {
        Swal.fire("Reenviar invitación", "Realizando petición...");
        Swal.showLoading();
        request(`/api/auth/resend`,{
            method: 'POST',
            body: JSON.stringify({
                business: item._id
            })
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                Swal.fire(
                    'Reenviar invitación',
                    'La invitación se ha enviado con éxito',
                    'success'
                );
            }else{
                Swal.fire('Reenviar invitación', "No se pudo enviar la invitación", "error");
            }
        })
    }

    return (
        <Container fluid className="admin-businesses page">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12}>
                            <h1>
                                Directorio
                            </h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Container className="content">
                <Row className="justify-content-md-center py-5">
                    <Col md={6} xs={12}>
                        <a href="/admin/directorio/nuevo" className="btn btn-custom-pink-outline-light px-5">Registrar nuevo negocio</a>
                    </Col>
                </Row>
                <Row className="justify-content-md-center py-5">
                    <Col md={8} xs={12}>
                        <Row className="justify-content-md-center py-5">
                            <Col md={6} xs={12}>
                                <button onClick={()=> sortBy('name')} className="btn btn-secondary w-100">Ordenar por nombre</button>
                            </Col>
                            <Col md={6} xs={12}>
                                <button onClick={()=> sortBy('created_at')} className="btn btn-secondary w-100">Ordenar por fecha creación</button>
                            </Col>
                        </Row>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                { businessItems.map((item:Business, key:number)=>(
                                    <tr key={key}>
                                        <td>{ item.name }</td>
                                        <td>
                                            <Row>
                                                <Col xs={12} md={4}>
                                                    <a
                                                        href={`/admin/directorio/editar/${item._id}`}
                                                        className="btn btn-primary"
                                                        >
                                                        Editar
                                                    </a>
                                                </Col>
                                                {
                                                    (item.user && !item.user.status) && (
                                                        <Col xs={12} md={4}>                                                    
                                                            <button className="btn btn-secondary ms-1" onClick={() => resendInvitation(item)} title="Reenviar invitación">
                                                                Reenviar
                                                            </button>
                                                        </Col>
                                                    )
                                                }
                                                <Col xs={12} md={4}>
                                                    <Button
                                                        onClick={()=>{removeBusiness(item)}}
                                                        className="btn btn-danger"
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
export default Businesses;