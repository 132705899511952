import React from 'react';
import Facebook from '../assets/ico-facebook.svg';
import Website from '../assets/website-ico.svg';
import Instagram from '../assets/ico-instagram.svg';

interface IProp {
    className?: string;
    type?: string;
}

function SocialNetwork(props: IProp){
    const accounts = [{
        name: "Facebook",
        link: "https://www.facebook.com/Acocen-411911385663643/",
        ico: Facebook,
        hide: 'header'
    },{
        name: "Instagram",
        link: "https://instagram.com/aguasvoyalcentro?utm_medium=copy_link",
        ico: Instagram
    },{
        name: "Facebook",
        link: "https://www.facebook.com/aguasvoyalcentro",
        ico: Facebook,
        hide: 'footer'
    },{
        name: "Website",
        link: "https://www.acocen.com.mx/",
        ico: Website
    }];    

    return (
        <div className={`social-networks ${props.className}`}>
            {
                accounts.map((item,key)=>(
                    <React.Fragment>
                        {
                            (!item.hide || props.type !== item.hide) && (
                                <a key={key} href={item.link} target="_blank" rel="noreferrer">
                                    <img src={item.ico} alt={item.name} />
                                </a>
                            )
                        }
                    </React.Fragment>
                ))
            }
        </div>
    )
}

export default SocialNetwork;