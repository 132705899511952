import React, { useRef } from 'react';
import DefaultProfilePicture from '../../assets/default_profile_picture.svg';
import EditPencil from '../../assets/edit_pencil.svg';
import { request } from '../../services/Request';
import '../../styles/profile-picture.scss';
import { ResponseResult } from '../../types';
import Swal from 'sweetalert2';

interface IProps {
    id?: string;
    src?: string;
    onUpload?: ()=>void;
}

function ProfilePicture (props: IProps){
    const { id, src, onUpload } = props;
    const fileInputRef = useRef<HTMLInputElement>(null);

    const onFileInputChange = (event:any) => {
        if(event.target.files[0]){
            const formData = new FormData();
            formData.append(
                "profile_picture",
                event.target.files[0]
              );
              formData.append(
                  "field",
                  'profile_picture'
                );
            request(`/api/businesses/${id}`,{
                method: 'PUT',
                body: formData,
                file: true,
            }).then((result:ResponseResult)=>{
                if(result.error){
                    if(result.error.status === 413){
                        Swal.fire("Subir imagen", "El archivo es muy grande");
                    } else {
                        Swal.fire("Subir imagen", "Ocurrió un error");
                    }
                } else {
                    if (typeof onUpload === "function"){
                        onUpload();
                    }
                }
            });
        }
    }

    const onClickPencil = () => {
        if(fileInputRef.current){
            fileInputRef.current.click()
        }
    }

    return (
        <div className="admin-business-profile-picture">
            <img src={src || DefaultProfilePicture} alt="Perfil" />
            { id && (
                <>
                    <div className="edit-pencil">
                        <img src={EditPencil} alt="Cambiar imágen" onClick={onClickPencil} />
                    </div>
                    <input
                        accept="image/jpg, image/jpeg"
                        onChange={onFileInputChange}
                        name="profile_picture"
                        ref={fileInputRef}
                        type="file"
                        className="d-none"
                        />
                        <small>Solo se aceptan imagenes de tipo jpg o jpeg</small>
                </>
            )}
        </div>
    )
}

export default ProfilePicture;