import { configureStore } from '@reduxjs/toolkit';
import eventsReducer from './eventsSlice';
import placesReducer from './placesSlice';
import couponsSlice from './couponsSlice';
import categoriesSlice from './categoriesSlice';
import businessesSlice from './businessesSlice';
import adminsSlice from './adminsSlice';
import usersSlice from './usersSlice';
import noticesSlice from './noticesSlice';

export const store = configureStore({
  reducer: {
      events: eventsReducer,
      places: placesReducer,
      coupons: couponsSlice,
      categories: categoriesSlice,
      businesses: businessesSlice,
      admins: adminsSlice,
      users: usersSlice,
      notices: noticesSlice
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch