import React from 'react';
import '../styles/card.scss';

interface Props {
    call: any
}

function CallCard (props: Props){
    const { call } = props;

    return (
        <div className="custom-card blue only-title">
            <a href={call.url ? call.url : `https://agsdigital.com.mx/home/announcements/detail/${call.id_convocatoria}`} target="_blank" rel="noreferrer">
                <div className="custom-card-header" style={{backgroundImage: `url(${call.ruta_imagen})`}}>
                    <div className="overlay"></div>
                </div>
                <div className="custom-card-body">
                    <h3>{call.titulo}</h3>
                </div>
            </a>
        </div>
    )
}
export default CallCard;