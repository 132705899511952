import { format } from "date-fns";
import { es } from 'date-fns/locale'

export const formatLongDate = (date: string) =>{
    date = date.replace("T00:00:00.000Z","T10:00:00.000Z");
    const dateObject = new Date(date);
    return format(dateObject, "EEEE d  MMMM, y", { locale: es });
}

export const formatCouponDate = (date: string) =>{
    date = date.replace("T00:00:00.000Z","T10:00:00.000Z");
    const dateObject = new Date(date);
    return format(dateObject, "dd/MMMM/yyyy", { locale : es})
}

export const formatInputDate = (date:string) => {
    date = date.replace("T00:00:00.000Z","T10:00:00.000Z");
    const dateObject = new Date(date);
    return format(dateObject, "yyyy-MM-dd")
}