import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { request } from '../../services/Request';
import { ResponseResult } from '../../types';

interface FormData {
    url: string;
}

interface Props {
    onClose: ()=>void;
    businessId: string;
}

function NewVideo(props: Props) {
    const { onClose, businessId } = props;

    const initialForm : FormData = { url: ''};
    const [formData,setFormData] = useState<FormData>(initialForm);

    const onCancel = () => {
        setFormData(initialForm);
        onClose();
    }

    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        if(!formData.url){
            Swal.fire("Video", "Es necesario ingresa la url del video",'error');
        }else{
            request('/api/businesses/videos',{
                method: 'POST',
                body: JSON.stringify({
                    url: formData.url ? formData.url : '',
                    business: businessId
                }),
            })
            .then((result:ResponseResult)=>{
                if(result.data){
                    setFormData(initialForm);
                    Swal.fire("Video", "El registro se ha guardado correctamente",'success');
                    onClose();
                }else{
                    Swal.fire("Video", "Error a crear registro",'error');
                }
            })
        }
    }


    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let data : any = formData;
        data[e.target.name] = e.target.value;
        setFormData(data);
    }

    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col>Nuevo video</Col>
            </Row>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formInputName">
                <Form.Label column sm="2" className="text-end">
                    Url del video
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="text" name="url" placeholder="" required onChange={onChange} />
                </Col>
                <Form.Label column sm={{span:10, offset: 2}} className="text-end">
                    <small>Ejemplo: https://www.youtube.com/watch?v=AAAAAAAAAAA</small>
                </Form.Label>
            </Form.Group>
            <Row>
                <Col>
                    <Form.Group className="mb-3 py-0" controlId="formbutton">
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit"
                                className="btn-custom-pink btn-clock my-2">
                                Guardar
                            </Button>
                            <Button
                                className="btn-custom-blue ms-5 my-2"
                                onClick={onCancel}
                                >
                                Cancelar
                            </Button>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    )
}

export default NewVideo;