import React from 'react';

function Navigation({pages}:{pages:string[]}){

    const getPages = (text:string[])=>{
        return text.map((item,index)=>(
            <span key={`navi-${index}`}>{ ' > '}<span className={index+1 === text.length ? 'bold' : ''}>{ item }</span></span>
        ))
    }

    return (
        <div id="navigation">
            <p>Inicio { getPages(pages) }</p>
        </div>
    );
}

export default Navigation;