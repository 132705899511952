import React from 'react';
import { Event } from '../types';
import { formatLongDate } from '../utils/date'
import EditPencil from '../assets/edit_pencil.svg';
import '../styles/card.scss';

interface Props {
    event: Event,
    onClick?: ()=>void,
}

function EventCard (props: Props){
    const { event, onClick } = props;

    return (
        <div className="custom-card pink mb-1 mt-0">
            <div className="custom-card-header" style={{backgroundImage: `url(${event.image_url})`}}>
                <div className="overlay"></div>
            </div>
            <div className="custom-card-body justify-content-center">
                <h3>{event.title}</h3>
                <hr></hr>
                <p className="text-center mb-0 date">{formatLongDate(event.date)}</p>
                <p className="text-center mb-0 date">{event.time} hrs, {event.venue}</p>
                <p>{event.description && event.description.length > 165 ? event.description.substring(0, 165)+"..." : event.description}</p>
                {
                    event.site_url && (
                        <a href={event.site_url} rel="noreferrer" target="_blank" className="btn btn-custom-blue w-100 text-white">Ir al sitio oficial del evento</a>
                    )
                }
            </div>
            { typeof onClick === 'function' && (
                <div className="edit-pencil">
                    <img src={EditPencil} alt="Actualizar" onClick={onClick}/>
                </div>
            )}
        </div>
    )
}
export default EventCard;