import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import '../../styles/page.scss';
import { Notice } from '../../types';
import Navigation from '../../components/Navigation';

const NoticesList = () => {

    const notices = useSelector((state: RootState) => state.notices);

    return (
        <Container fluid className="page">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12}>
                            <h1>
                                Avisos
                            </h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Container className="content-center">
                <Row>
                    <Col xs={12} md={{span:10,offset:1}}>
                        <Navigation pages={['Avisos']}></Navigation>
                    </Col>
                    <Col xs={12} md={{span:2,offset:10}}>
                        <a href="/perfil/Comunicacion" className="btn btn-custom-white">
                            Regresar
                        </a>
                    </Col>
                </Row>
                <Row className="justify-content-md-center py-5">
                    <Col md={8} xs={12}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Título</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                { notices.loaded && notices.data.map((item:Notice, key:number)=>(
                                    <tr key={key}>
                                        <td>{ item.title }</td>
                                        <td>
                                            <a
                                                href={'/perfil/Comunicacion/avisos/'+item._id}
                                                className="btn btn-custom-pink-outline-light px-5"
                                            >
                                                Leer
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
export default NoticesList;
