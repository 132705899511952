import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {request} from '../../services/Request';
import {ResponseResult, Business} from '../../types';
import Swal from 'sweetalert2';
import '../../styles/page.scss';
import { useParams } from 'react-router-dom';
import BusinessProfile from '../../components/business/BusinessProfile';


interface RouteParam {
    id: string
}

const EditBusiness = () => {
    const [business,setBusiness] = useState<Business | null>(null);
    const { id } = useParams<RouteParam>();


    const getBusiness = useCallback(()=>{
        const businessId = id;
        request(`/api/businesses/${businessId}`)
            .then((result:ResponseResult)=>{
                if(result.data){
                    setBusiness(result.data);
                }else{
                    Swal.fire("Negocio", "Ocurrió un error al obtener negocio");
                }
            }).catch(()=>{
                Swal.fire("Negocio", "Ocurrió un error al obtener negocio");
            })
    },[id]);

    useEffect(()=>{
        getBusiness();
    },[getBusiness]);

    return (
        <Container fluid className="business-invite page">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12}>
                            <h1>
                                Editar Socio
                            </h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Row className="justify-content-md-center py-5">
                <Col md={{span:10,offset:1}} sm={12} xs={12}>
                    {
                        business && (
                            <BusinessProfile business={business} onUpdate={getBusiness} />
                        )
                    }
                </Col>
            </Row>
        </Container>
    )
}
export default EditBusiness;