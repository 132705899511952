import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { request } from '../../services/Request';
import { Event, ResponseResult } from '../../types';
import { formatInputDate } from '../../utils/date';

interface Props {
    onClose: ()=>void;
    event: Event;
    onSuccess: ()=>void;
}
function EditEvent(props: Props) {
    const { onClose, event, onSuccess } = props;
    const [formData,setFormData] = useState<Event>({...event})

    const onCancel = () => {
        setFormData(event);
        onClose();
    }
    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        if(formData.description && formData.description.length > 165){
            Swal.fire("Evento", "La descripción no puede exceder un máximo de 165 caracteres", "error");
        }else{
            const fdata = new FormData();
            fdata.append("title",formData?.title);
            fdata.append("description",formData?.description);
            fdata.append("date",formData?.date);
            fdata.append("time",formData?.time);
            fdata.append("type",formData?.type);
            fdata.append("venue",formData?.venue);
            if(formData.site_url && formData.site_url !== '' ){
                fdata.append("site_url",formData?.site_url);
            }
            fdata.append("image_url",formData?.image_url);
    
            request(`/api/events/${formData._id}`,{
                method: 'PUT',
                body: fdata,
                file: true,
            })
            .then((result:ResponseResult)=>{
                if(result.data){
                    onSuccess();
                    Swal.fire("Eventos", "El registro se ha actualizado correctamente",'success');
                    onClose();
                } else {
                    Swal.fire("Eventos", "Error al editar el registro",'error');
                }
            })
        }
    };
    const onChange = (e:any) => {
        let data : any = formData;
        data[e.target.name] = e.target.files ? e.target.files[0] : e.target.value;
        setFormData({...data});
    }
    return (
        <Form onSubmit={onSubmit} id="edit">
            <Row>
                <Col>Editar Evento</Col>
            </Row>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextTitle">
                <Form.Label column sm="2" className="text-end">
                    Título
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="text" name="title" defaultValue={formData.title} placeholder="" required onChange={onChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextDescription">
                <Form.Label column sm="2" className="text-end">
                    Descripción <br/>( {formData.description ? formData.description.length + "/165" : '0/165'} caracteres)
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="text" name="description" defaultValue={formData.description} placeholder="" required onChange={onChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextDate">
                <Form.Label column sm="2" className="text-end">
                    Fecha
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="date" name="date" defaultValue={formatInputDate(formData.date)} placeholder="" required onChange={onChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextTime">
                <Form.Label column sm="2" className="text-end">
                    Hora
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="time" name="time" defaultValue={formData.time} placeholder="" required onChange={onChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextVenue">
                <Form.Label column sm="2" className="text-end">
                    Lugar
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="text" name="venue" defaultValue={formData.venue} placeholder="" required onChange={onChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextVenue">
                <Form.Label column sm="2" className="text-end">
                    Enlace del sitio
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="text" name="site_url" defaultValue={formData.site_url} placeholder="" onChange={onChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column sm="2" className="text-end">
                        Tipo de Evento
                </Form.Label>
                <Col sm="10">
                    <Form.Select aria-label="Default select example" name="type" required onChange={onChange}>
                        <option value="Público">Público</option>
                        <option value="Interno">Interno</option>
                    </Form.Select>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextImage">
                <Form.Label column sm="2" className="text-end">
                    Imágen
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="file" name="image_url" accept="image/jpg, image/jpeg" placeholder="" onChange={onChange} />
                    <small>Solo se aceptan imagenes de tipo jpg o jpeg</small>
                    <br></br>
                    <small>Dejar en blanco para no actualizar</small>
                </Col>
            </Form.Group>
            <Row>
                <Col>
                    <Form.Group className="mb-3 py-0" controlId="formbutton">
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit"
                                className="btn-custom-pink btn-clock my-2">
                                Guardar
                            </Button>
                            <Button
                                onClick={onCancel}
                                className="btn-custom-blue ms-5 my-2"
                                >
                                 Cancelar
                                </Button>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    )
}
export default EditEvent;