
import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Container, Button, Table } from 'react-bootstrap';
import { User } from '../../types';
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store';
import '../../styles/page.scss';
import { getAdmins } from '../../redux/adminsSlice';
import { useDispatch } from 'react-redux';
import NewAdmin from "../../components/admin/NewAdmin";

function Admins(){

    const admins = useSelector((state: RootState) => state.admins);
    const dispatch = useDispatch();
    const [showNewForm,setShowNewForm] = useState(false);

    const toggleNewForm = () => {
        setShowNewForm(!showNewForm);
    }

    const hideNewForm = () => {
        setShowNewForm(false);
    }

    const refresh = useCallback(() => {
        dispatch(getAdmins());
    },[dispatch])

    useEffect(()=>{
        refresh();
    },[refresh]);


    return (
        <Container fluid className="admin-places page">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12} md={{span:10,offset:1}}>
                            <h1>Administradores</h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Container className="content">
                <Row className="justify-content-md-center py-5">
                    <Col md={{span:12}}>
                        {showNewForm && (
                            <NewAdmin onSuccess={refresh} onClose={hideNewForm} />
                        )}
                        {!showNewForm && (
                            <Button className="btn-custom-pink-outline-light px-5" onClick={toggleNewForm}>Invitar Admin</Button>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={{span:12,offset:0}}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                { admins.data.map((item:User)=>(
                                        <tr>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Admins;