import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../styles/page.scss';

function Comunication(){
    return (
        <Container fluid className="comunicacion-panel page">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12}>
                            <h1>
                                Comunicación Interna
                            </h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Container className="content">
                <Row>
                    <Col xs={12} md={4}>
                        <a className="btn btn-custom-pink-outline-light px-5 w-100" href="/perfil/Comunicacion/avisos">Avisos</a>
                    </Col>
                    <Col xs={12} md={4}>
                        <a className="btn btn-custom-pink-outline-light px-5 w-100" href="/perfil/Comunicacion/EventosInternos">Eventos</a>
                    </Col>
                    <Col xs={12} md={4}>
                        <a className="btn btn-custom-pink-outline-light px-5 w-100" href="/perfil/Comunicacion/convocatorias">Convocatorias</a>
                    </Col>
                </Row>
                <Row className="justify-content-center pt-5">
                    <Col xs={12} md={4}>
                        <a className="btn btn-custom-pink-outline-light px-5 w-100" href="https://agsdigital.com.mx/">AGS Digital</a>
                    </Col>
                </Row>
            </Container>

        </Container>
    )
}

export default Comunication;