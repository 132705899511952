import React from 'react';
import '../styles/page.scss';

function NotFound() {
    return(
      <div className="NotFound-container page text-center mt-3">
        <h1>
          Error 404, esta página no existe.
        </h1>
        <h4 className="text-center">
          Lo sentimos, la página que estás buscando no pudo ser encontrada.
        </h4>
        <p className="text-center">Puedes regresar a la página de <a href="/">Inicio</a>, o contáctanos en <a href="mailto://contacto@acocen.com.mx">contacto@acocen.com.mx</a> o por teléfono <b>449-1712-334</b> y <b>449-1712-335</b> si no encuentras lo que buscabas.</p>
      </div>
    );
}

export default NotFound;
