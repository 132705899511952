import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Accordion } from 'react-bootstrap';
import AcocenIcon from '../assets/acocen-icon.svg';
import '../styles/page.scss';
import '../styles/search.scss';
import { request } from '../services/Request';
import Swal from 'sweetalert2';
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { ResponseResult, Coupon, Product } from '../types';
import CouponCard from '../components/CouponCard';
import BusinessProduct from '../components/business/BusinessProduct';

function Search(){
    const [searchResults,setSearchResults] = useState<any[]>([])
    const { search } = useLocation();

    useEffect(()=>{
        const query = queryString.parse(search);
        console.log('search',query)
        if(query.q) {
            request(`/api/search?q=${query.q}`)
                .then((result:ResponseResult)=>{
                    if(result.data){
                        setSearchResults(result.data);
                    }else{
                        Swal.fire("Búsqueda", "Ocurrió un error al obtener resultados");
                    }
                });
        }
    },[search]);

    return (
        <div className="search page">
            <div className="header">
                <div className="overlay"></div>
                <img src={AcocenIcon} className="site-icon" alt="logo" />
                <div className="title">
                    <Row>
                        <Col xs={12} md={{span:10,offset:1}}>
                            <h1>Resultados</h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Container className="content">
                { searchResults && searchResults.map((business,index)=>(
                    <Row key={`result-${index}`}>
                        <Col>
                            <div className="seachResult my-3">
                                <div className="name">
                                    <a href={`/comercio/${business.slug}`} >
                                        { business.name }
                                    </a>
                                </div>
                                <div className="description">{ business.description }</div>
                                { business.categories && (
                                    <div className="businessCategories">Categorias: { business.categories.join(', ') }</div>
                                )}
                                <Accordion className="mt-2">
                                    { business.products && (
                                        <Accordion.Item eventKey="0" className="businessProducts">
                                            <Accordion.Header>Productos</Accordion.Header>
                                            <Accordion.Body>                                                
                                                <Row>
                                                    {
                                                        business.products.map((product:Product,key:number)=>(
                                                            <Col key={`product-${key}`} xs={12} md={3} className="mt-2">
                                                                <BusinessProduct product={product}></BusinessProduct>
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )}
                                    { business.coupons && (
                                        <Accordion.Item eventKey="1" className="businessCoupons">
                                            <Accordion.Header>Cupones</Accordion.Header>
                                            <Accordion.Body>                                                
                                                <Row>
                                                    {
                                                        business.coupons.map((coupon:Coupon,key:number)=>(
                                                            <Col key={`coupon-${key}`} xs={12} md={3} className="mt-2">
                                                                <CouponCard coupon={coupon}></CouponCard>
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )}
                                </Accordion>
                            </div>
                        </Col>
                    </Row>
                ))}
            </Container>
        </div>
    )
}

export default Search;