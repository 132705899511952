import React, {useState, useEffect} from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import {request} from '../../services/Request';
import { ResponseResult } from '../../types';
import CallCard from '../../components/CallCard';
import Swal from 'sweetalert2';
import '../../styles/page.scss';
import '../../styles/calls.scss';

function CallList(){

    const [calls, setCalls] = useState([]);

    useEffect(()=>{
        getCalls();
    },[]);

    const getCalls = () => {
        Swal.fire('Convocatorias', 'Cargando convocatorias...');
        Swal.showLoading();
        request('https://agsdigital.com.mx/admin/api/convocatorias?api_token=&filter={}',{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setCalls(result.data.data);
            }else{
                Swal.fire("Convocatorias", "No se pudieron cargar las convocatorias", "error");
            }
        })
    }

    const filterCalls = (type:number) => {
        return calls.filter((item:any)=>{
            if(item.fecha_cierre){
                const endDate = new Date(item.fecha_cierre);
                const currentTime = new Date();
                return item.categoria_id === type && endDate.getTime() >= currentTime.getTime();
            }else{
                return item.categoria_id === type;
            }
        });
    }

    return (
        <div className="calls page">
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Col xs={12} md={{span:2,offset:10}}>
                        <a href="/perfil/Comunicacion" className="btn btn-custom-white mt-5">
                            Regresar
                        </a>
                    </Col>
                        <Tabs defaultActiveKey="calls" id="tabs" className="mb-5 mt-auto">
                            <Tab eventKey="calls" title="Convocatorias">
                                <Row>
                                    {
                                        filterCalls(1).map((item:any,key:number)=>(
                                            <Col xs={12} md={4}>
                                                <CallCard call={item}></CallCard>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </Tab>
                                <Tab eventKey="financing" title="Financiamientos">
                                    <Row>
                                        {
                                            filterCalls(3).map((item:any,key:number)=>(
                                                <Col xs={12} md={4}>
                                                    <CallCard call={item}></CallCard>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Tab>
                            <Tab eventKey="covenants" title="Convenios">
                                <Row>
                                    {
                                        filterCalls(2).map((item:any,key:number)=>(
                                            <Col xs={12} md={4}>
                                                <CallCard call={item}></CallCard>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </Tab>
                        </Tabs>
                </Col>
            </Row>
        </div>
    )
}

export default CallList;