import { useState, useEffect } from 'react';
import { User } from '../types';

export interface UserState {
    data?: User,
    isLoggedIn: boolean
  }

export const useUser = () => {
  const [user, setUser] = useState<UserState>({
      isLoggedIn: false
  });
  useEffect(() => {
      if(localStorage.user){
          const userData = JSON.parse(localStorage.user);
            setUser({
                data: userData,
                isLoggedIn: true
            });
      }
  },[]);
  return user;
}