import React from "react";
import { Business } from "../types";
import "../styles/business-card.scss";
import { Col, Row } from "react-bootstrap";
import Phone from "../assets/Tel.svg";
import Web from "../assets/website-ico.svg";
import Location from "../assets/location-ico.svg";
import Facebook from "../assets/facebook-ico.svg";
import Instagram from "../assets/Instagram.svg";
import Youtube from "../assets/Youtube.svg";
import WhatsappIco from "../assets/whatsapp-ico.svg";

interface Props {
  business: Business;
}

function BusinessCard(props: Props) {
  const { business } = props;

  return (
    <div className="business-card shadow p-2">
      <Row className="business-card-header">
        <Col className="business-card-title">{business.name}</Col>
      </Row>
      <Row className="business-card-body align-items-center">
        <Col className="business-card-image">
          { business.profile_picture && (
            <img src={business.profile_picture} alt={business.name} />
          )}
          { !business.profile_picture && (
            <p>{business.name}</p>
          )}
        </Col>
          <Col className="business-card-contact">
            { business.whatsapp && (
              <div className="p-0 mx-1 float-end">
                <a
                  href={`https://api.whatsapp.com/send?phone=+521${business.whatsapp}&text=hola,%20desde%20%C2%A1Aguas,%20voy%20al%20centro!`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={WhatsappIco} alt={business.whatsapp} />
                </a>
              </div>
            )}
            {business.website && (
              <div className="p-0 float-end mx-1">
                <a href={business.website} target="_blank" rel="noreferrer">
                  <img src={Web} alt="web" />
                </a>
              </div>
            )}
            {business.location && (
              <div className="p-0 float-end mx-1">
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${business.location?.lat},${business.location?.lng}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Location} alt="location" />
                </a>
              </div>
            )}
            {business.facebook && (
              <div className="p-0 float-end mx-1">
                <a href={business.facebook} target="_blank" rel="noreferrer">
                  <img src={Facebook} alt="facebook" />
                </a>
              </div>
            )}
            {business.instagram && (
              <div className="p-0 float-end mx-1">
                <a href={business.instagram} target="_blank" rel="noreferrer">
                  <img src={Instagram} alt="instagram" />
                </a>
              </div>
            )}
            {business.youtube && (
              <div className="p-0 float-end mx-1">
                <a href={business.youtube} target="_blank" rel="noreferrer">
                  <img src={Youtube} alt="youtube" />
                </a>
              </div>
            )}
          </Col>
        <Col xs={12} className="p-0">
          <div className="business-card-phone mt-2">
            <a
              href={`tel:${business.phone}`}
              target="_blank"
              rel="noreferrer"
              className="d-flex align-items-stretch"
            >
              <img src={Phone} alt="teléfono" className="me-3" />{" "}
              <span className="d-flex align-items-center">
                {business.phone}
              </span>
            </a>
          </div>
          <a
            href={`/comercio/${business.slug}`}
            className="btn btn-custom-pink"
          >
            Más información
          </a>
        </Col>
      </Row>
    </div>
  );
}
export default BusinessCard;
