import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Category } from '../types';
import { request } from '../services/Request';
import { ResponseResult } from '../types';

// Define a type for the slice state
export interface CategoriesState {
    data: Category[],
    loaded: boolean,
    error: string
  }

  // Define the initial state using that type
  const initialState: CategoriesState = {
    data: [],
    loaded: false,
    error: ''
  }

export const getCategories = createAsyncThunk(
  'categories/getCategories',
  async () => {
    const response : ResponseResult = await request('/api/categories',{});
    return response.data;
  }
)

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder.addCase(getCategories.pending, (state) => {
        state.loaded = false;
      });
      builder.addCase(getCategories.fulfilled, (state, { payload }) => {
        state.loaded = true;
        state.data = payload;
      });
      builder.addCase(getCategories.rejected, (state, action) => {
        state.loaded = false;
        if(action.payload){
          state.error = 'failed';
        } else {
          state.error = action.error.message || '';
        }
      });
    },
  })

  export default categoriesSlice.reducer