import React, { useState } from 'react';
import { Coupon, ResponseResult } from '../types';
import '../styles/coupon-card.scss';
import { Button, Modal } from 'react-bootstrap';
import { formatCouponDate } from '../utils/date';
import { useUser } from '../hooks/userHook';
import { request } from '../services/Request';

interface Props {
    coupon: Coupon,
    status?: boolean,
    url?: string
}

function CouponCard (props: Props){
    const { coupon, status, url } = props;
    const [show, setShow] = useState(false);
    const [showUrl, setShowUrl] = useState(false);
    const user = useUser();

    const handleClose = () => setShow(false);
    const handleShow = () => {
        if(url){
            setShowUrl(true);
        }else{
            if (user.isLoggedIn) {
                request(`/api/coupons/assign/${coupon._id}`)
                    .then((result:ResponseResult)=>{
                    });
            }
            setShow(true);
        }
    };

    return (
        <>
            <div className="coupon-card my-1">
                <div className="title">
                    <h2>{ coupon.product }</h2>
                </div>
                <div className="coupon-card-header" style={{backgroundImage: `url(${coupon.image_url})`}}>
                </div>
                <div className="coupon-card-body">
                        <div className={ !coupon.text_color || coupon.text_color === 'blanco' ? "description p-3" : "description p-3 text-"+coupon.text_color }>{ coupon.description }</div>
                        <div>
                            {
                                !status && (
                                    <Button className="btn-custom-pink" onClick={handleShow}>Obtener descuento</Button>
                                )
                            }
                        </div>
                    <div className="ends">Vigencia {formatCouponDate(coupon.ends)}</div>
                </div>
                <div className="coupon-card-business">{ coupon.business ? coupon.business.name : '' }</div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                { !user.isLoggedIn && (
                    <div>
                        <h3 className="purple mb-5">Obtener promoción</h3>
                        Para obtener el cupón es necesario {` `}
                        <a href="/login">
                            Iniciar sesión
                        </a>, si no tienes cuenta puedes <a href="/registrar">
                            registrarte
                        </a>
                    </div>
                )}
                { user.isLoggedIn && (
                    <div>
                        <h3 className="purple mb-5">Cupón canjeado con éxito</h3>
                        ¡Hemos enviado el cupón a tu correo electrónico! Canjéalo y disfrutalo cuanto antes, también los puedes consultar en 
                        <a href="/mis-cupones">
                            mis cupones
                        </a>.
                    </div>
                )}
                </Modal.Body>
            </Modal>
            <Modal show={showUrl} onHide={() => {setShowUrl(false)}}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h3 className="purple mb-5">Obtener promoción</h3>
                        <p>Escanea el siguiente código:</p>
                        <img src={ url } alt="qr" className ="w-100"></img>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default CouponCard;