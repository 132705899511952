import React, {useEffect, useState} from 'react';
import { Row, Col } from 'react-bootstrap';
import { Event } from '../types';
import Navigation from '../components/Navigation';
import AcocenIcon from '../assets/acocen-icon.svg';
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store';
import '../styles/page.scss';
import EventCard from '../components/EventCard';

function Events(){

    const all_events = useSelector((state: RootState) => state.events);
    const [events, setEvents] = useState<Event[]>([]);
    useEffect(() => {
        const data = all_events.data.filter((item:Event) => {
            return item.type === 'Público';
        });
        setEvents(data);
    },[all_events])

    return (
        <div className="events page">
            <div className="header">
                <div className="overlay"></div>
                <img src={AcocenIcon} className="site-icon" alt="logo" />
                <div className="title">
                    <Row>
                        <Col xs={12} md={{span:10,offset:1}}>
                            <h1>Eventos</h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="content">
                <Row>
                    <Col xs={12} md={{span:10,offset:1}}>
                        <Navigation pages={['Eventos']}></Navigation>
                        <Row>
                            {
                             events.map((item:Event, index:number)=>(
                                    <Col xs={12} md={4} key={`event-${index}`}>
                                        <EventCard event={item} />
                                    </Col>
                                ))
                            }
                            {
                            !events || (events.length === 0 && (
                                <Col xs={12} md={12}>
                                    <div className="NoEvent-container page text-center mt-3">
                                        <h1>
                                            Por el momento no hay eventos.
                                        </h1>
                                        <p className="text-center">
                                            Puedes regrsar a la página de
                                            <a href="/"> Inicio</a> o
                                            <a href="/contacto"> Contáctanos </a>
                                                si no encuentras lo que buscabas.
                                        </p>
                                    </div>
                                </Col>
                            ))
                            }
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default Events;