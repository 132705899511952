import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Notice } from '../types';
import { request } from '../services/Request';
import { ResponseResult } from '../types';

// Define a type for the slice state
export interface NoticesState {
    data: Notice[],
    loaded: boolean,
    error: string
  }

  // Define the initial state using that type
  const initialState: NoticesState = {
    data: [],
    loaded: false,
    error: ''
  }

export const getNotices = createAsyncThunk(
  'notices/getNotices',
  async () => {
    const response : ResponseResult = await request('/api/notices',{});
    return response.data;
  }
)

export const noticesSlice = createSlice({
    name: 'notices',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder.addCase(getNotices.pending, (state) => {
        state.loaded = false;
      });
      builder.addCase(getNotices.fulfilled, (state, { payload }) => {
        state.loaded = true;
        state.data = payload;
      });
      builder.addCase(getNotices.rejected, (state, action) => {
        state.loaded = false;
        if(action.payload){
          state.error = 'failed';
        } else {
          state.error = action.error.message || '';
        }
      });
    },
  })

  export default noticesSlice.reducer