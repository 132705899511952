import React, { useState } from "react";
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Place, ResponseResult } from '../../types';
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store';
import '../../styles/page.scss';
import NewPlace from "../../components/admin/NewPlace";
import EditPlace from "../../components/admin/EditPlace";
import Swal from 'sweetalert2';
import PlaceCard from "../../components/PlaceCard";
import { getPlaces } from '../../redux/placesSlice';
import { request } from '../../services/Request';
import { useDispatch } from 'react-redux';

function AdminPlaces(){

    const places = useSelector((state: RootState) => state.places)
    const [showNewForm,setShowNewForm] = useState(false);
    const [showEditForm,setShowEditForm] = useState(false);
    const [placeToEdit,setPlaceToEdit] = useState<Place | null>(null);
    const dispatch = useDispatch();

    const toggleNewForm = () => {
        setShowNewForm(!showNewForm);
    }
    const toggleEditForm = () => {
        setShowEditForm(!showEditForm);
    }
    const hideNewForm = () => {
        setShowNewForm(false);
    }
    const hideEditForm = () => {
        setShowEditForm(false);
    }

    const refresh = ()=>{
        dispatch(getPlaces());
    }

    const deletePlace = (_id:string) => {
        Swal.fire({
            title: '¿Estás seguro de eliminarlo?',
            text: "No podrás revertir este cambio",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar!',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                request(`/api/places/${_id}`,{
                    method: 'DELETE'
                })
                .then((result:ResponseResult)=>{
                    if(result.data){
                        dispatch(getPlaces());
                        Swal.fire(
                            'Eliminado!',
                            'El lugar de interés ha sido eliminada',
                            'success'
                        )
                    }else{
                        Swal.fire('Puntos de interés', "No se pudo eliminar el punto", "error");
                    }
                })
            }
          })
    }

    return (
        <Container fluid className="admin-places page">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12} md={{span:10,offset:1}}>
                            <h1>Lugares de Interés</h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Container className="content">
            <Row className="justify-content-md-center py-5">
                    <Col md={{span:12}}>
                        {showNewForm && (
                            <NewPlace onSuccess={refresh} onClose={hideNewForm} />
                        )}
                        {showEditForm && placeToEdit && (
                            <EditPlace onClose={hideEditForm} onSuccess={refresh} place={placeToEdit} />
                        )}
                        {!showNewForm && (
                            <Button className="btn-custom-pink-outline-light px-5" onClick={toggleNewForm}>Crear Lugar</Button>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={{span:12,offset:0}}>
                        <Row>
                            {
                             places.data.map((item:Place)=>(
                                    <Col xs={12} md={6}>
                                        <PlaceCard place={item} onClick={()=>{hideNewForm();toggleEditForm();setPlaceToEdit(item);}} />
                                        <Button 
                                         onClick={()=>{deletePlace(item._id)}}
                                        className="btn btn-block btn-custom-pink w-100">Eliminar</Button>
                                    </Col>
                                ))
                            }
                            { places.data.length === 0 && (
                                <h3>No hay eventos próximos</h3>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default AdminPlaces;