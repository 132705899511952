import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "../../styles/page.scss";
import "../../styles/business-profile.scss";
import ProfilePicture from "../../components/business/ProfilePicture";
import EditPencil from "../../assets/edit_pencil.svg";
import { Business, ResponseResult } from "../../types";
import Swal from "sweetalert2";
import { request } from "../../services/Request";
import BusinessProducts from "./BusinessProducts";
import BusinessVideos from "./BusinessVideos";
import BusinessAddress from "./BusinessAddress";
import BusinessCategories from "./BusinessCategories";
import Coupons from "./Coupons";
import Facebook from "../../assets/facebook-ico.svg";
import Whatsapp from "../../assets/whatsapp-ico.svg";
import Instagram from "../../assets/Instagram.svg";
import Youtube from "../../assets/Youtube.svg";
import WebsiteIco from "../../assets/website-ico.svg";
import Phone from "../../assets/Tel.svg";

interface IProps {
  onUpdate: () => void;
  business: Business;
}

const BusinessProfile = (props: IProps) => {
  const [enableNameEditForm, setEnableNameEditForm] = useState(false);
  const inputNameRef = useRef<HTMLInputElement>(null);

  const [enableDescriptionEditForm, setEnableDescriptionEditForm] = useState(false);
  const inputDescriptionRef = useRef<HTMLInputElement>(null);

  const [enableFacebookEditForm, setEnableFacebookEditForm] = useState(false);
  const inputFacebookRef = useRef<HTMLInputElement>(null);

  const [enableWhatsappEditForm, setEnableWhatsappEditForm] = useState(false);
  const inputWhatsappRef = useRef<HTMLInputElement>(null);

  const [enablePhoneEditForm, setEnablePhoneEditForm] = useState(false);
  const inputPhoneRef = useRef<HTMLInputElement>(null);

  const [enableYoutubeEditForm, setEnableYoutubeEditForm] = useState(false);
  const inputYoutubeRef = useRef<HTMLInputElement>(null);

  const [enableInstagramEditForm, setEnableInstagramEditForm] = useState(false);
  const inputInstagramRef = useRef<HTMLInputElement>(null);

  const [enableWebsiteEditForm, setEnableWebsiteEditForm] = useState(false);
  const inputWebsiteRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputNameRef.current) {
      inputNameRef.current.focus();
    }
  }, [enableNameEditForm]);

  const { onUpdate, business } = props;

  useEffect(() => {
    if (inputDescriptionRef.current) {
      inputDescriptionRef.current.focus();
    }
  }, [enableDescriptionEditForm]);
  useEffect(() => {
    if (inputFacebookRef.current) {
      inputFacebookRef.current.focus();
    }
  }, [enableFacebookEditForm]);
  useEffect(() => {
    if (inputWhatsappRef.current) {
      inputWhatsappRef.current.focus();
    }
  }, [enableWhatsappEditForm]);
  useEffect(() => {
    if (inputPhoneRef.current) {
      inputPhoneRef.current.focus();
    }
  }, [enablePhoneEditForm]);
  useEffect(() => {
    if (inputYoutubeRef.current) {
      inputYoutubeRef.current.focus();
    }
  }, [enableYoutubeEditForm]);
  useEffect(() => {
    if (inputInstagramRef.current) {
      inputInstagramRef.current.focus();
    }
  }, [enableInstagramEditForm]);
  useEffect(() => {
    if (inputWebsiteRef.current) {
      inputWebsiteRef.current.focus();
    }
  }, [enableInstagramEditForm]);

  const hideNameEditForm = () => {
    setEnableNameEditForm(false);
  };
  const hideDescriptionEditForm = () => {
    setEnableDescriptionEditForm(false);
  };
  const hideFacebookEditForm = () => {
    setEnableFacebookEditForm(false);
  };
  const hideWhatsappEditForm = () => {
    setEnableWhatsappEditForm(false);
  };
  const hidePhoneEditForm = () => {
    setEnablePhoneEditForm(false);
  };
  const hideYoutubeEditForm = () => {
    setEnableYoutubeEditForm(false);
  };
  const hideInstagramEditForm = () => {
    setEnableInstagramEditForm(false);
  };
  const hideWebsiteEditForm = () => {
    setEnableWebsiteEditForm(false);
  };
  const showFacebookEditForm = () => {
    setEnableFacebookEditForm(true);
  };
  const showWhatsappEditForm = () => {
    setEnableWhatsappEditForm(true);
  };
  const showPhoneEditForm = () => {
    setEnablePhoneEditForm(true);
  };
  const showYoutubeEditForm = () => {
    setEnableYoutubeEditForm(true);
  };
  const showInstagramEditForm = () => {
    setEnableInstagramEditForm(true);
  };
  const showWebsiteEditForm = () => {
    setEnableWebsiteEditForm(true);
  };
  const showNameEditForm = () => {
    setEnableNameEditForm(true);
  };
  const showDescriptionEditForm = () => {
    setEnableDescriptionEditForm(true);
  };

  const onSubmit = (
    e: React.SyntheticEvent,
    inputRef: React.RefObject<HTMLInputElement>,
    onSuccess: () => void,
    type: string | null = null
  ) => {
    e.preventDefault();
    if (inputRef.current && inputRef.current.value.length) {
      const formData = new FormData();
      formData.append(inputRef.current.name, inputRef.current.value);
      formData.append("field", inputRef.current.name);
      if(type === 'link'){
        formData.append("type", type);
      }
      save(formData, onSuccess);
    } else {
      Swal.fire("Guardar", "El campo no puede estar vacio");
    }
  };
  const save = (formData: FormData, onSuccess: () => void) => {
    request(`/api/businesses/${business._id}`, {
      method: "PUT",
      body: formData,
      file: true,
    }).then((result: ResponseResult) => {
      if (result.error) {
        if(result.error.status === 420){
          Swal.fire("Registrar", "El nombre del negocio ya existe. Favor de escoger otro.","error");
        }else{
          Swal.fire("Guardar", "Ocurrió un error", 'error');
        }
      } else {
        onSuccess();
        onUpdate();
      }
    });
  };
  return (
    <Container fluid className="business-profile">
      <Row className="justify-content-md-center py-5">
        <Col md={4}>
          <ProfilePicture
            id={business._id}
            src={business.profile_picture}
            onUpload={onUpdate}
          />
        </Col>
        <Col md={8} className="d-flex align-items-center py-1">
          {!enableNameEditForm && (
            <div>
              <div className="fs-2 fw-light d-inline">{business.name}</div>
              <div className="edit-pencil d-inline">
                <img
                  src={EditPencil}
                  className="pencil-icon"
                  alt="Cambiar nombre"
                  onClick={showNameEditForm}
                />
              </div>
            </div>
          )}
          {enableNameEditForm && (
            <div className=" w-100">
              <Form
                onSubmit={(e) => {
                  onSubmit(e, inputNameRef, hideNameEditForm);
                }}
              >
                <input
                  type="text"
                  placeholder="Ingresa el nombre de tu negocio"
                  name="name"
                  ref={inputNameRef}
                  className="fs-2 fw-light w-100"
                  defaultValue={business.name}
                />
                <div>
                  <Button type="submit" className="btn-custom-pink">
                    Guardar
                  </Button>
                  <Button
                    onClick={hideNameEditForm}
                    className="btn-custom-pink-outline-light"
                  >
                    Cancelar
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={8} className="d-flex align-items-center">
          {!enableDescriptionEditForm && (
            <div>
              <div className="description-field fs-3 fw-light d-inline">
              { business.description && business.description !=='' ? business.description : 'Ingresa una descripción acerca de tu negocio' }
              </div>
              <div className="edit-pencil d-inline">
                <img
                  src={EditPencil}
                  className="pencil-icon"
                  alt="Cambiar descripcion"
                  onClick={showDescriptionEditForm}
                />
              </div>
            </div>
          )}
          {enableDescriptionEditForm && (
            <div className=" w-100">
              <Form
                onSubmit={(e) => {
                  onSubmit(e, inputDescriptionRef, hideDescriptionEditForm);
                }}
              >
                <input
                  type="text"
                  placeholder="Ingresa una descripción a cerca de tu negocio"
                  name="description"
                  ref={inputDescriptionRef}
                  className="fs-2 fw-light w-100"
                  defaultValue={business.description}
                />
                <div>
                  <Button type="submit" className="btn-custom-pink">
                    Guardar
                  </Button>
                  <Button
                    onClick={hideDescriptionEditForm}
                    className="btn-custom-pink-outline-light"
                  >
                    Cancelar
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={8} className="d-flex align-items-center">
          {!enableFacebookEditForm && (
            <div>
              <div className="facebook-field fs-3 fw-light d-inline">
                { business.facebook && business.facebook !=='' ? business.facebook : 'Ingresa la página de Facebook de tu negocio' }
              </div>
              <div className="edit-pencil d-inline">
                <img
                  src={EditPencil}
                  className="pencil-icon"
                  alt="Cambiar Facebook"
                  onClick={showFacebookEditForm}
                />
                <img src={Facebook} alt="facebook" />
              </div>
            </div>
          )}
          {enableFacebookEditForm && (
            <div className=" w-100">
              <Form
                onSubmit={(e) => {
                  onSubmit(e, inputFacebookRef, hideFacebookEditForm,'link');
                }}
              >
                <input
                  type="text"
                  placeholder="Ingresa tu Facebook"
                  name="facebook"
                  ref={inputFacebookRef}
                  className="fs-2 fw-light w-100"
                  defaultValue={business.facebook}
                />
                <div>
                  <Button type="submit" className="btn-custom-pink">
                    Guardar
                  </Button>
                  <Button
                    onClick={hideFacebookEditForm}
                    className="btn-custom-pink-outline-light"
                  >
                    Cancelar
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={8} className="d-flex align-items-center">
          {!enableWhatsappEditForm && (
            <div>
              <div className="whatsapp-field fs-3 fw-light d-inline">
                { business.whatsapp && business.whatsapp !== '' ? business.whatsapp : 'Ingresa el número de WhatsApp de tu negocio'}
              </div>
              <div className="edit-pencil d-inline">
                <img
                  src={EditPencil}
                  className="pencil-icon"
                  alt="Cambiar tu whatsapp"
                  onClick={showWhatsappEditForm}
                />
                <img src={Whatsapp} alt="facebook" />
              </div>
            </div>
          )}
          {enableWhatsappEditForm && (
            <div className=" w-100">
              <Form
                onSubmit={(e) => {
                  onSubmit(e, inputWhatsappRef, hideWhatsappEditForm);
                }}
              >
                <input
                  type="text"
                  placeholder="Ingresa tu whatsapp"
                  name="whatsapp"
                  ref={inputWhatsappRef}
                  className="fs-2 fw-light w-100"
                  defaultValue={business.whatsapp}
                />
                <div>
                  <Button type="submit" className="btn-custom-pink">
                    Guardar
                  </Button>
                  <Button
                    onClick={hideWhatsappEditForm}
                    className="btn-custom-pink-outline-light"
                  >
                    Cancelar
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={8} className="d-flex align-items-center">
          {!enablePhoneEditForm && (
            <div>
              <div className="phone-field fs-3 fw-light d-inline">
                { business.phone && business.phone !== '' ? business.phone : 'Ingresa el número de teléfono de tu negocio'}
              </div>
              <div className="edit-pencil d-inline">
                <img
                  src={EditPencil}
                  className="pencil-icon"
                  alt="Cambiar tu teléfono"
                  onClick={showPhoneEditForm}
                />
                <img src={Phone} alt="teléfono" />
              </div>
            </div>
          )}
          {enablePhoneEditForm && (
            <div className="w-100">
              <Form
                onSubmit={(e) => {
                  onSubmit(e, inputPhoneRef, hidePhoneEditForm);
                }}
              >
                <input
                  type="text"
                  placeholder="Ingresa tu teléfono"
                  name="phone"
                  ref={inputPhoneRef}
                  className="fs-2 fw-light w-100"
                  defaultValue={business.phone}
                />
                <div>
                  <Button type="submit" className="btn-custom-pink">
                    Guardar
                  </Button>
                  <Button
                    onClick={hidePhoneEditForm}
                    className="btn-custom-pink-outline-light"
                  >
                    Cancelar
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={8} className="d-flex align-items-center">
          {!enableYoutubeEditForm && (
            <div>
              <div className="youtube-field fs-3 fw-light d-inline">
                {business.youtube && business.youtube !== '' ? business.youtube : 'Ingresa el canal de YouTube de tu negocio'}
              </div>
              <div className="edit-pencil d-inline">
                <img
                  src={EditPencil}
                  className="pencil-icon"
                  alt="Cambiar canal de youtube"
                  onClick={showYoutubeEditForm}
                />
                <img src={Youtube} alt="youtube" />
              </div>
            </div>
          )}
          {enableYoutubeEditForm && (
            <div className=" w-100">
              <Form
                onSubmit={(e) => {
                  onSubmit(e, inputYoutubeRef, hideYoutubeEditForm,'link');
                }}
              >
                <input
                  type="text"
                  placeholder="Ingresa tu canal de YouTube"
                  name="youtube"
                  ref={inputYoutubeRef}
                  className="fs-2 fw-light w-100"
                  defaultValue={business.youtube}
                />
                <div>
                  <Button type="submit" className="btn-custom-pink">
                    Guardar
                  </Button>
                  <Button
                    onClick={hideYoutubeEditForm}
                    className="btn-custom-pink-outline-light"
                  >
                    Cancelar
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={8} className="d-flex align-items-center">
          {!enableInstagramEditForm && (
            <div>
              <div className="instagram-field fs-3 fw-light d-inline">
                {business.instagram && business.instagram !== '' ? business.instagram : 'Ingresa la página de Instagram de tu negocio'}
              </div>
              <div className="edit-pencil d-inline">
                <img
                  src={EditPencil}
                  className="pencil-icon"
                  alt="Cambiar instagram"
                  onClick={showInstagramEditForm}
                />
                <img src={Instagram} alt="instagram" />
              </div>
            </div>
          )}
          {enableInstagramEditForm && (
            <div className=" w-100">
              <Form
                onSubmit={(e) => {
                  onSubmit(e, inputInstagramRef, hideInstagramEditForm,'link');
                }}
              >
                <input
                  type="text"
                  placeholder="Ingresa tu pagina de Instagram"
                  name="instagram"
                  ref={inputInstagramRef}
                  className="fs-2 fw-light w-100"
                  defaultValue={business.instagram}
                />
                <div>
                  <Button type="submit" className="btn-custom-pink">
                    Guardar
                  </Button>
                  <Button
                    onClick={hideInstagramEditForm}
                    className="btn-custom-pink-outline-light"
                  >
                    Cancelar
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={8} className="d-flex align-items-center">
          {!enableWebsiteEditForm && (
            <div>
              <div className="website-field fs-3 fw-light d-inline">
                {business.website && business.website !== '' ? business.website : 'Ingresa el sitio web de tu negocio'}
              </div>
              <div className="edit-pencil d-inline">
                <img
                  src={EditPencil}
                  className="pencil-icon"
                  alt="Cambiar website"
                  onClick={showWebsiteEditForm}
                />
                <img src={WebsiteIco} alt="Website" />
              </div>
            </div>
          )}
          {enableWebsiteEditForm && (
            <div className=" w-100">
              <Form
                onSubmit={(e) => {
                  onSubmit(e, inputWebsiteRef, hideWebsiteEditForm,'link');
                }}
              >
                <input
                  type="text"
                  placeholder="Ingresa tu pagina web"
                  name="website"
                  ref={inputWebsiteRef}
                  className="fs-2 fw-light w-100"
                  defaultValue={business.website}
                />
                <div>
                  <Button type="submit" className="btn-custom-pink">
                    Guardar
                  </Button>
                  <Button
                    onClick={hideWebsiteEditForm}
                    className="btn-custom-pink-outline-light"
                  >
                    Cancelar
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <BusinessProducts businessId={business._id} />
        </Col>
      </Row>
      <Row>
        <Col>
          <BusinessVideos businessId={business._id} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Coupons businessId={business._id} />
        </Col>
      </Row>
      <Row>
        <Col>
          <BusinessAddress
            onSubmit={onSubmit}
            onSave={save}
            location={business.location}
            address={business.address}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BusinessCategories onSave={save} categories={business.categories} />
        </Col>
      </Row>
    </Container>
  );
};
export default BusinessProfile;