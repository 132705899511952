import React, { useState } from "react";
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Event } from '../../types';
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store';
import '../../styles/page.scss';
import NewEvent from "../../components/admin/NewEvent";
import Swal from 'sweetalert2';
import { getEvents } from '../../redux/eventsSlice';
import { useDispatch } from 'react-redux';
import EditEvent from "../../components/admin/EditEvent";
import { request } from '../../services/Request';
import EventCard from "../../components/EventCard";

function AdminEvents(){

    const events = useSelector((state: RootState) => state.events)
    const [showNewForm,setShowNewForm] = useState(false);
    const [showEditForm,setShowEditForm] = useState(false);
    const [eventToEdit,setEventToEdit] = useState<Event | null>(null);
    const dispatch = useDispatch();

    const toggleNewForm = () => {
        setShowNewForm(!showEditForm);
    }
    const toggleEditForm = () => {
        setShowEditForm(!showEditForm);
        setTimeout(()=>{
            document.getElementById('edit')?.scrollIntoView();
        }, 500);

    }
    const hideNewForm = () => {
        setShowNewForm(false);
    }
    const hideEditForm = () => {
        setShowEditForm(false);
    }

    const refresh = ()=>{
        dispatch(getEvents());
    }

    const deleteEvent = (_id:string) => {
        request(`/api/events/${_id}`,{
            method: 'DELETE'})
        Swal.fire({
            title: '¿Estas seguro?',
            text: "No podras revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
          }).then((result) => {
            if (result.isConfirmed) {
                dispatch(getEvents());
              Swal.fire(
                'Eliminado!',
                'El evento ha sido eliminada',
                'success'
              )
            }
          })
    }

    return (
        <Container fluid className="admin-events page">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12} md={{span:10,offset:1}}>
                            <h1>Eventos</h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Container className="content">
            <Row className="justify-content-md-center py-5">
                    <Col md={12} xs={12}>
                        {showNewForm && (
                            <NewEvent onSuccess={refresh} onClose={hideNewForm} />
                        )}
                        {showEditForm && eventToEdit && (
                            <EditEvent onClose={hideEditForm} onSuccess={refresh} event={eventToEdit} />
                        )}
                        {!showNewForm && (
                            <Button className="btn-custom-pink-outline-light px-5" onClick={() => { hideEditForm();toggleNewForm(); }}>Crear Evento</Button>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={{span:12,offset:0}}>
                        <Row>
                            {
                             events.data.map((item:Event)=>(
                                    <Col xs={12} md={6}>
                                        <EventCard event={item} onClick={()=>{hideNewForm();toggleEditForm();setEventToEdit(item);}} />
                                        <Button 
                                         onClick={()=>{deleteEvent(item._id);toggleEditForm();}}
                                        className="btn btn-block btn-custom-pink w-100">
                                            Eliminar
                                        </Button>
                                    </Col>
                                ))
                            }
                            { events.data.length === 0 && (
                                <h3>No hay eventos próximos</h3>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default AdminEvents;