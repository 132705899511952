import React from 'react';
import { Place } from '../types';
import EditPencil from '../assets/edit_pencil.svg';
import '../styles/card.scss';

interface Props {
    place: Place,
    onClick?: ()=>void,
}

function PlaceCard (props: Props){
    const { place, onClick } = props;

    return (
        <div className="custom-card blue mb-1 mt-0">
            <div className="custom-card-header" style={{backgroundImage: `url(${place.image_url})`}}>
                <div className="overlay"></div>
            </div>
            <div className="custom-card-body">
                <h3>{place.title}</h3>
                <hr></hr>
                <p>{place.description}</p>
            </div>
            { typeof onClick === 'function' && (
                <div className="edit-pencil">
                <img src={EditPencil} alt="Actualizar" onClick={onClick} />
            </div>
            )}
        </div>
    )
}
export default PlaceCard;