import React, { useState } from 'react';
import { Navbar, Container, Nav, Row, Col, Form, Dropdown } from 'react-bootstrap';
import { useReactPWAInstall } from "react-pwa-install";
import '../styles/header.scss';
import { FaBars } from "react-icons/fa";
import Logo from '../assets/AVC-b.rosa.svg';
import LogoColor from '../assets/AVC-color.svg';
import Buscador from '../assets/buscador.svg';
import SocialNetwork from './SocialNetwork';
import { useUser } from '../hooks/userHook';
import { logout } from '../services/auth';
import Swal from 'sweetalert2';

function Header(){
    
    const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
    const [showSearchBar, setSearchBar] =  useState(false);
    const user = useUser();
    const onLogout = () => {
        Swal.fire({
            title: '¿Realmente desea cerrar sesión?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
          })
          .then((result) => {
            if (result.isConfirmed) {
              logout();
            }
          })
    }

    const handleClick = () => {
        pwaInstall({
          title: "Aguas, voy al centro",
          logo: LogoColor,
          features: (
            <ul>
              <li>Acceso directo a la plataforma</li>
            </ul>
          ),
          description: "Conoce el centro de aguascalientes: eventos, puntos de interés y promociones.",
        })
        .then(() => {
            alert("Aplicación instalada.")
        })
        .catch(() => {
            alert("Se escogió no instalarla.")
        });
      };

    return (
        <div id="header">
            <Row className="align-items-center">
                <Col xs={12} md={3} className=" logo-column d-none d-md-block">
                    <Navbar.Brand id="logo" href="/">
                        <img src={Logo} className="header-logo" alt="logo"/>
                    </Navbar.Brand>
                </Col>
                <Col xs={12} md={9}>                    
                    <div className="top-bar">
                        <Row className="align-items-center">
                            <Col xs={6} md={{span:3,offset:1}}>
                                {
                                    showSearchBar && (
                                        <Form action="/buscar">
                                            <Form.Group controlId="search">
                                                <Form.Control type="text" name="q" placeholder="Buscar..." />
                                            </Form.Group>
                                        </Form>
                                    )
                                }
                            </Col>
                            <Col xs={4} md={3}>
                                <SocialNetwork type={"header"} className="float-end"/>
                            </Col>
                            { !user.isLoggedIn && (
                                <Col className="d-none d-md-inline-block" md={2}>
                                    <a href="/login" className="btn btn-custom-pink-outline w-100">
                                        Iniciar sesión
                                    </a>
                                </Col>
                            )}
                            { !user.isLoggedIn && (
                                <Col className="d-none d-md-inline-block" md={2}>
                                    <a href="/registrar" className="btn btn-custom-pink w-100">
                                        Regístrate
                                    </a>
                                </Col>
                            )}
                            { user.isLoggedIn && !user.data?.business && (
                                <Col className="d-none d-md-block" md={2}>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-button-user" variant="custom-pink" className="col-12 text-truncate">
                                        {user.data?.name}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="custom-pink text-center">
                                        <Dropdown.Item href="/perfil"> Mi perfil </Dropdown.Item>
                                        <Dropdown.Item href="/mis-cupones"> Mis Cupones </Dropdown.Item>
                                    <Dropdown.Item onClick={onLogout}> Cerrar Sesión </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                </Col>
                            )}
                            {  user.data?.business && user.data?.business && (
                                <Col className="d-none d-md-block" md={2}>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-button-user" variant="custom-pink" className="col-12 text-truncate">
                                        {user.data?.name}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="custom-pink text-center">
                                        <Dropdown.Item href="/perfil"> Mi Negocio </Dropdown.Item>
                                        <Dropdown.Item href="/mis-cupones"> Mis Cupones </Dropdown.Item>
                                        <Dropdown.Item href="/perfil/Comunicacion"> Comunicación </Dropdown.Item>
                                    <Dropdown.Item onClick={onLogout}> Cerrar Sesión </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                </Col>
                            )}
                            { user.isLoggedIn && user.data?.role && (
                                <Col xs={5} md={2} className="d-none d-sm-none d-md-block">
                                    <a href="/admin" className="btn btn-custom-pink w-100">
                                        Admin
                                    </a>
                                </Col>
                            )}
                            <Col xs={2} md={1} className="text-center pt-1">
                                <img src={Buscador} onClick={()=>setSearchBar(!showSearchBar)} className="cursor-pointer" alt="buscar" />
                            </Col>
                        </Row>
                    </div>
                    { user.isLoggedIn && (
                        <div className="users-menu d-none d-md-none d-xl-none">
                            <Row>
                                <Col>
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-button-user" variant="custom-pink" >
                                            {user.data?.name}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="custom-pink text-center">
                                            <Dropdown.Item href="/perfil"> Mi perfil </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item href="/"> Mis datos </Dropdown.Item>
                                            <Dropdown.Item href="/"> Mis cupones </Dropdown.Item>
                                            <Dropdown.Item href="/"> Ver noticias </Dropdown.Item>
                                            <Dropdown.Divider />
                                        <Dropdown.Item onClick={onLogout}> Cerrar Sesión </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                {
                                    user.data?.role && (
                                        <Col>
                                            <a href="/admin" className="btn btn-custom-pink w-100">
                                                Admin
                                            </a>
                                        </Col>
                                    )
                                }
                            </Row>
                        </div>
                    )}
                    <Navbar bg="light" expand="lg">
                        <Container fluid>       
                            <Navbar.Brand id="logo" href="/" className="d-block d-md-none d-lg-none">
                                <img src={Logo} className="header-logo" alt="logo"/>
                            </Navbar.Brand>             
                            <Navbar.Toggle aria-controls="menu">
                                <FaBars className="navbar-toggle-ico" />
                            </Navbar.Toggle>
                            <Navbar.Collapse id="menu">
                                <Nav className="my-5 my-md-0 ms-auto">
                                    <Nav.Link href="/">Inicio</Nav.Link>
                                    <Nav.Link href="/directorio">Directorio</Nav.Link>
                                    <Nav.Link href="/eventos">Eventos</Nav.Link>
                                    <Nav.Link href="/lugares">Puntos de interés</Nav.Link>
                                    <Nav.Link href="/cupones">Promociones</Nav.Link>    
                                    <Nav.Link className="pink-nav px-5" href="https://agsdigital.com.mx/">AGS Digital</Nav.Link>                        
                                </Nav>
                                { !user.isLoggedIn && (
                                    <div className="d-xs-inline-block d-md-none">
                                        <a href="/login" className="btn btn-custom-pink-outline w-100">
                                            Iniciar sesión
                                        </a>
                                    </div>
                                )}
                                { !user.isLoggedIn && (
                                    <div className="d-xs-inline-block d-md-none">
                                        <a href="/registrar" className="btn my-3 btn-custom-pink w-100">
                                            Regístrate
                                        </a>
                                    </div>
                                )}
                                { user.isLoggedIn && (
                                    <div className="d-xs-inline-block d-md-none">
                                        <a href="/perfil" className="btn btn-custom-pink-outline w-100">
                                            {user.data?.name}
                                        </a>
                                    </div>
                                )}
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </Col>
            </Row>
            <div className="d-block d-md-none d-lg-none">
                { supported() && !isInstalled() && (
                    <div className="pwa-menu" onClick={handleClick}>
                        Instalar como aplicación en teléfono
                    </div>
                )}                             
            </div>
        </div>
    )
}

export default Header;