import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({setRole,...props}:any) => {
	if(!setRole && localStorage.token){
		return <Route {...props} />;
	}else if(localStorage.user){
		const user = JSON.parse(localStorage.user);
		if(setRole === 'negocio'){
			if(user.business){
				return <Route {...props} />;
			}
			else{
				return <Redirect to="/"/>;
			}
		}
		else if(setRole.length > 0){
			if(localStorage.token && user && setRole.includes(user.role)){
				return <Route {...props} />;
			}else{
				return <Redirect to="/"/>;
			}
		}else{
			if(localStorage.token && user && user.role === setRole){
				return <Route {...props} />;
			}else{
				return <Redirect to="/"/>;
			}
		}
	}else{
		return <Redirect to="/"/>;
	}
};

export default ProtectedRoute;
