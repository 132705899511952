import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import { ResponseResult, Product } from '../../types';
import Swal from 'sweetalert2';
import { request } from '../../services/Request';
import NewProduct from './NewPoduct';
import BusinessProduct from './BusinessProduct';


interface IProps {
    businessId: string;
}

const BusinessProducts = (props: IProps) => {

    const { businessId } = props;

    const deleteProduct = (id:string) => {
        Swal.fire({
            title: '¿Estas seguro?',
            text: "No podras revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
          }).then((result) => {
            if (result.isConfirmed) {
                request(`/api/products/${id}`,{
                    method: 'DELETE'}).then((res: ResponseResult)=>{
                        if(res.data){
                            getProducts();
                            Swal.fire(
                                'Producto',
                                'El producto ha sido eliminado',
                                'success'
                            )
                        }else{
                            Swal.fire(
                                'Producto',
                                'El producto no ha podido ser eliminado',
                                'error'
                            )
                        }
                    } )
            }
          })
    }

    const [products,setProducts] = useState<Product[]>([]);

    const [showNewForm,setShowNewForm] = useState(false);
    //const [showEditForm,setShowEditForm] = useState(false);

    const getProducts = useCallback(()=>{
        request(`/api/products/business/${businessId}`,{
            method: 'get'
        }).then((result:ResponseResult)=>{
            if(result.error){
                Swal.fire("Actualizar", "Ocurrió un error al cargar los productos");
            } else {
                setProducts(result.data);
            }
        });
    }, [businessId]);

    useEffect(()=>{
        getProducts();
    },[getProducts])

    const toggleNewForm = () => {
        setShowNewForm(!showNewForm);
    }
    /*
    const toggleEditForm = () => {
        setShowEditForm(!showEditForm);
    }*/
    const hideNewForm = () => {
        setShowNewForm(false);
    }
    /*
    const hideEditForm = () => {
        setShowEditForm(true);
    }*/

    return (
        <Container className="business-profile-products mt-5 pt-5">
            <Row>
                <Col>
                    <div className="fs-2 text-center">Productos</div>
                </Col>
            </Row>
            <Row className="py-5">
                    <Col xs={12}>
                        {showNewForm && (
                            <NewProduct businessId={businessId} onClose={()=>{hideNewForm();getProducts();}} />
                        )}
                        {
                            products.length >= 6 && (
                                <Alert variant="warning">
                                    Has llegado al límite de productos!
                                </Alert>
                            )
                        }
                        {/*showEditForm && categoryToEdit && (
                            <EditCategoryForm onClose={hideEditForm} category={categoryToEdit} />
                        )*/}
                        {!showNewForm && products.length<6 && (
                            <Button className="btn-custom-pink-outline-light px-5" 
                                onClick={toggleNewForm}>
                                    Nuevo Producto
                            </Button>
                        )}
                    </Col>
                </Row>
            {products && (
                <Row>
                    {products.map((item:Product,key:number)=>(
                            <Col key={`product-${key}`} md={4} className="mt-4">
                                <BusinessProduct product={item}/>
                                    <Button 
                                        onClick={()=>{deleteProduct(item._id);}}
                                        className="btn-custom-pink btn-block w-100 my-1">Eliminar
                                    </Button>
                            </Col>
                    ))}
                </Row>
            )}
        </Container>
    )
}
export default BusinessProducts;