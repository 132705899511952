import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import { ResponseResult, Video } from '../../types';
import Swal from 'sweetalert2';
import { request } from '../../services/Request';
import NewVideo from './NewVideo';

interface IProps {
    businessId: string;
}

const BusinessVideos = (props: IProps) => {

    const { businessId } = props;

    const deleteVideo = (id:string) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'No'
          }).then((result) => {
            if (result.isConfirmed) {
                request(`/api/businesses/videos/${businessId}/${id}`,{
                    method: 'DELETE'}).then((res: ResponseResult)=>{
                        if(res.data){
                            getVideos();
                            Swal.fire(
                                'Video',
                                'El video ha sido eliminado',
                                'success'
                            )
                        }else{
                            Swal.fire(
                                'Video',
                                'El video no ha podido ser eliminado',
                                'error'
                            )
                        }
                    } )
              
            }
          })
    }

    const [videos,setVideos] = useState<Video[]>([]);

    const [showNewForm,setShowNewForm] = useState(false);

    const getVideos = useCallback(()=>{
        request(`/api/businesses/${businessId}`,{
            method: 'get'
        }).then((result:ResponseResult)=>{
            if(result.error){
                Swal.fire("Actualizar", "Ocurrió un error al cargar los videos");
            } else {
                setVideos(result.data.videos);
            }
        });
    }, [businessId]);

    useEffect(()=>{
        getVideos();
    },[getVideos])

    const toggleNewForm = () => {
        setShowNewForm(!showNewForm);
    }

    const hideNewForm = () => {
        setShowNewForm(false);
    }

    const getVideoId = (item:Video) => {
        return item.url.indexOf('watch') > 0 ? item.url.replace("https://www.youtube.com/watch?v=", "") : item.url.replace("https://youtu.be/", "");
    }

    return (
        <Container className="business-profile-products mt-5 pt-5">
            <Row>
                <Col>
                    <div className="fs-2 text-center">Videos</div>
                </Col>
            </Row>
            <Row className="py-5">
                    <Col xs={12}>
                        {showNewForm && (
                            <NewVideo businessId={businessId} onClose={()=>{hideNewForm();getVideos();}} />
                        )}
                        {
                            videos.length >= 6 && (
                                <Alert variant="warning">
                                   ¡Has llegado al límite de videos!
                                </Alert>
                            )
                        }
                        {!showNewForm && videos.length<6 && (
                            <Button className="btn-custom-pink-outline-light px-5" 
                                onClick={toggleNewForm}>
                                    Nuevo video
                            </Button>
                        )}
                    </Col>
                </Row>
            {videos.length > 0 && (
                <Row>
                    {videos.map((item:Video,key:number)=>(
                            <Col key={`video-${key}`} md={4} className="mt-4">
                                <div className="video-responsive">
                                    <iframe src={"https://www.youtube.com/embed/"+ getVideoId(item)} allowFullScreen></iframe>
                                </div>
                                <Button 
                                    onClick={()=>{deleteVideo(item._id);}}
                                    className="btn-custom-pink btn-block w-100 my-1">Eliminar
                                </Button>
                            </Col>
                    ))}
                </Row>
            )}
        </Container>
    )
}
export default BusinessVideos;