import React, { useState} from 'react';
import { Button, Col, Form, Row} from 'react-bootstrap';
import Swal from 'sweetalert2';
import { getCoupons } from '../../redux/couponsSlice';
import { request } from '../../services/Request';
import { Coupon, ResponseResult } from '../../types';
import { useDispatch } from 'react-redux';

interface Props {
    onClose: ()=>void;
    coupon: Coupon
}

function EditCoupon(props: Props) {
    const { onClose, coupon } = props;
    const [formData,setFormData] = useState<Coupon>({...coupon})
    const dispatch = useDispatch();
    const onCancel = () => {
        onClose();
    }

    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        if(formData.description && formData.description.length > 60){
            Swal.fire('Cupón', 'La descripción no debe exceder los 60 caracteres','error');
        }else{
            Swal.fire("Cupón", "Guardando...");
            Swal.showLoading();
            const fdata = new FormData();
            fdata.append("product",formData?.product);
            fdata.append("description",formData?.description);
            fdata.append("starts",formData?.starts);
            fdata.append("ends",formData?.ends);
            fdata.append("text_color",formData?.text_color);
            request(`/api/coupons/${formData._id}`,{
                method: 'PUT',
                body: fdata,
                file: true,
            })
            .then((result:ResponseResult)=>{
                Swal.close();
                if(result.data){
                    dispatch(getCoupons());
                    Swal.fire("Cupones", "El cupón se ha actualizado correctamente",'success');
                    onClose();
                }
                else if(result.error.status === 401){
                    Swal.fire("Cupones", "No se puede duplicar un registro",'error');
                }
                else{
                    Swal.fire("Cupones", "Error al editar el cupon",'error');
                }
            })
        }
    };
    
    const onChange = (e:any) => {
        let data : any = formData;
        data[e.target.name] = e.target.value;
        setFormData({...data});
    }

    return (
        <>
            <Form onSubmit={onSubmit} className="mb-5">
                <Row>
                    <Col md="6">Editar Cupón</Col>
                </Row>
                <Form.Group as={Row} className="mb-3 py-1 " controlId="formInputProduct">
                    <Form.Label column sm="2" className="text-end">
                        Producto o Servicio
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control type="text" name="product" defaultValue={formData.product} placeholder="" required onChange={onChange} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 py-1 " controlId="formInputDescription">
                    <Form.Label column sm="2" className="text-end">
                        Descripción <br></br>({ formData.description ? formData.description.length+"/60" : "0/60" })
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control type="text" name="description" defaultValue={formData.description} placeholder="" required onChange={onChange} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 py-1 " controlId="textColor">
                    <Form.Label column sm="2" className="text-end">
                        Color de texto
                    </Form.Label>
                    <Col sm="10">
                        <Form.Select name="text_color" required onChange={onChange}>
                            <option value="blanco">Blanco</option>
                            <option value="azul">Azul</option>
                            <option value="rosa">Rosa</option>
                        </Form.Select>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 py-1 " controlId="formInputStarts">
                    <Form.Label column sm="2" className="text-end">
                        Fecha de inicio:
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control type="date" name="starts" defaultValue={formData.starts}  placeholder="" required onChange={onChange} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 py-1 " controlId="formInputEnds">
                    <Form.Label column sm="2" className="text-end">
                        Fecha que finaliza
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control type="date" name="ends" defaultValue={formData.ends} placeholder="" required onChange={onChange} />
                    </Col>
                </Form.Group>
                <Row>
                    <Col>
                        <Form.Group className="mb-3 py-0" controlId="formbutton">
                            <Col sm={{ span: 10, offset: 2 }}>
                                <Button type="submit"
                                    className="btn-custom-pink btn-clock my-2">
                                    Guardar
                                </Button>
                                <Button
                                    className="btn-custom-blue ms-5 my-2"
                                    onClick={onCancel}
                                    >
                                    Cancelar
                                </Button>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </>
    )
}
export default EditCoupon;