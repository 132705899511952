import React, {useEffect, useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import RestaurantIcon from '../assets/ico-restaurantes.svg';
import InterestIcon from '../assets/ico-lugares.svg';
import PromoIcon from '../assets/ico-promociones.svg';
import AcocenLogo from '../assets/acocen _logo.svg';
import '../styles/home.scss';
import { useSelector } from 'react-redux'
import { Event } from '../types';
import MiniHomeCarousel from '../components/MiniHomeCarousel';
import CouponRibbon from '../components/CouponRibbon';
import { RootState } from '../redux/store';
import HomeMap from '../components/HomeMap';
import HeaderCarousel from '../components/HeaderCarousel';

function Home(){

    const all_events = useSelector((state: RootState) => state.events);
    const [events,setEvents] = useState<Event[]>([]);

    useEffect(() => {
        const data = all_events.data.filter((item:Event) => {
            return item.type === 'Público';
        });
        setEvents(data);
    },[all_events]);

    const places = useSelector((state: RootState) => state.places);

    return (
        <div className="home-page">
            <HeaderCarousel></HeaderCarousel>
            <div className="first-section">
                <h1 className="text-center">Encuentra lo mejor del centro del país</h1>
                <Row>
                    <Col xs={12} md={4} className="mb-5">
                        <img src={RestaurantIcon} alt="Restaurant" />
                        <p>Come en los mejores lugares</p>
                        <a href="/directorio/restaurantes-y-fondas" className="btn btn-custom-pink">
                            Restaurantes
                        </a>
                    </Col>
                    <Col xs={12} md={4} className="mb-5">
                        <img src={InterestIcon} alt="interest" />
                        <p>Conoce lugares increíbles</p>
                        <a href="/lugares" className="btn btn-custom-pink">
                            Puntos de interés
                        </a>
                    </Col>
                    <Col xs={12} md={4} className="mb-5">
                        <img src={PromoIcon} alt="promo" />
                        <p>Las más grandes promociones</p>
                        <a href="/cupones" className="btn btn-custom-pink">
                            Canjea tus cupones
                        </a>
                    </Col>
                </Row>
            </div>
            <Container className="events-section" fluid>
                <Row>
                    <Col xs={12} md={{span:5,offset:1}} className="p-0">
                        <h2 className="title display-4 text-center mb-3">{ "Eventos" }</h2>
                        <MiniHomeCarousel
                            data={events}
                            loading={all_events.loading}
                        />
                    </Col>
                    <Col xs={12} md={5} className="p-0">
                        <h2 className="title display-4 text-center mb-3">{ "Puntos de interés" }</h2>
                        <MiniHomeCarousel
                            data={places.data}
                            loading={places.loading}
                        />
                    </Col>
                </Row>
            </Container>
            <Container className="coupons-section">
                <Row>
                    <Col>
                        <h1 className="display-2 text-center">Encuentra descuentos de las mejores marcas</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CouponRibbon />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col xs={12} md={6}>
                        <a href="/cupones" className="btn btn-custom-blue w-100">Ver todos los cupones</a>
                    </Col>
                </Row>

            </Container>
            <Container className="map-section m-0" fluid>
                <h1 className="text-center">¿Qué hay cerca de mí?</h1>
                <HomeMap />
            </Container>
            <Container className="join-us-section m-0 p-0" fluid>
                <Row>
                    <Col sm={12} md={8} className="ps-0 image"></Col>
                    <Col className="">
                        <div className="inner">
                            <img src={AcocenLogo} alt="Acocen" />
                            <p>Registra tu negocio y obten grandes beneficios.</p>
                            <a href="https://acocen.com.mx" target="_blank" rel="noreferrer" className="btn btn-custom-black">
                                Únete a Acocen
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Home;
