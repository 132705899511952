import React, { useEffect, useState } from 'react';
import { Row, Col, ListGroup, Dropdown } from 'react-bootstrap';
import { Business, Category, RouteParam } from '../types';
import '../styles/directory.scss';
import Navigation from '../components/Navigation';
import AcocenIcon from '../assets/acocen-icon.svg';
import { RootState } from '../redux/store';
import { useSelector } from 'react-redux';
import BusinessCard from '../components/BusinessCard';
import { useParams } from 'react-router-dom';

function Directory(){
    const { slug } = useParams<RouteParam>();
    const categories = useSelector((state: RootState) => state.categories);
    const businesses = useSelector((state: RootState) => state.businesses);
    const [categorySelected,setCategorySelected] = useState<string>('');

    useEffect(()=>{
        setCategorySelected(categories.data.filter((c)=>{ return c.slug === slug})[0]?.name || '')
    },[slug, categories.data])

    const highlightCategory = (slugSelected: string) => {
        return slug === slugSelected ? 'selected' : ''
    }

    return (
        <div className="directory page">
            <div className="header">
                <div className="overlay"></div>
                <img src={AcocenIcon} className="site-icon" alt="Logo" />
                <div className="title">
                    <Row>
                        <Col xs={12} md={{span:10,offset:1}}>
                            <h1>DIRECTORIO</h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="content">
                <Row>
                    <Col xs={12} md={{span:10,offset:1}}>
                        <Navigation pages={['Directorio']}></Navigation>
                        <Row>
                            <Col xs={12} md={{span:2}} className="p-0">
                                <div className="submenu d-none d-md-block">
                                    <ListGroup>
                                        { categories.loaded && categories.data && categories.data.map((category:Category,key:number)=>(
                                            <ListGroup.Item
                                                key={`category-${key}`}
                                                className={`ps-4 text-capitalize ${highlightCategory(category.slug)}`}
                                            >
                                                    <a href={`/directorio/${category.slug}`}><i className={"me-1 fas fa-"+category.icon}></i> {category.name}</a>
                                            </ListGroup.Item>
                                        ))}
                                        <ListGroup.Item className="ps-4 text-capitalize">
                                            <a href={`/directorio/`}>Todos</a>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                                <div className="d-block d-md-none d-xl-none text-center mb-2">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="custom-pink">
                                            Seleccionar una categoría
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href={`/directorio/`}>Todos</Dropdown.Item>
                                            { categories.loaded && categories.data.map((category:Category,key:number)=>(
                                                <Dropdown.Item className="ext-capitalize" href={`/directorio/${category.slug}`} key={`cat-${key}`}>
                                                    {category.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Col>
                            <Col xs={12} md={{span:10}}>
                                <div className="list">
                                    <Row>
                                        {
                                            businesses
                                            .data
                                            .filter((item:Business)=>{ return !slug || item.categories?.includes(categorySelected)})
                                            .map((item:Business,key:number)=>(
                                                <Col xs={12} md={6} xl={4} key={key}>
                                                    <BusinessCard business={item} />
                                                </Col>
                                            ))
                                        }
                                        {
                                            businesses.loaded && businesses.data &&
                                            (businesses.data.filter((item:Business)=>{ return !slug ||
                                            item.categories?.includes(categorySelected)}).length===0 &&
                                            (
                                                <Col xs={12} md={12}>
                                                    <div className="justify-content-center">
                                                        <h3>
                                                            Por el momento no hay negocios en esta categoría.
                                                        </h3>
                                                    </div>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Directory;