import React, { useState } from 'react';
import { Container, Row, Col, Button, Form} from 'react-bootstrap';
import {request} from '../../services/Request';
import {ResponseResult} from '../../types';
import Swal from 'sweetalert2';
import '../../styles/page.scss';
import { getBusinesses } from '../../redux/businessesSlice';
import { useDispatch } from 'react-redux';

interface FormData {
    name?: string,
    phone?: string,
    email?: string
}

const InviteBusiness = () => {
    const dispatch = useDispatch()
    const [formData,setFormData] = useState<FormData>({})

    const onSubmit = (e:React.SyntheticEvent) => {
        Swal.fire("Negocio", "Enviando petición...");
        Swal.showLoading()
        e.preventDefault();
        Swal.fire("Directorio", "Creando negocio...");
        Swal.showLoading();
        request('/api/businesses',{
            method: 'POST',
            body: JSON.stringify(formData)
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                dispatch(getBusinesses());
                if(result.data.exists){
                    Swal.fire("Registrar", "El correo ya existe para un usuario registrado. El correo de invitación ha sido enviado.","success")
                    .then(()=>{
                        window.location.href = "/admin/directorio";
                    })
                }else{
                    Swal.fire("Registrar", "El correo de invitación ha sido enviado.","success")
                    .then(()=>{
                        window.location.href = "/admin/directorio";
                    })
                }
            }else{
                if(result.error.status === 420){
                    Swal.fire("Registrar", "El nombre del negocio ya existe. Favor de escoger otro.","error");
                }else if(result.error.status === 421){
                    Swal.fire("Registrar", "El correo ingresado ya tiene un negocio. Favor de escoger otro.","error");
                }else{
                    Swal.fire("Registrar", "Ocurrió un error al registrar negocio","error");
                }
            }
        })
    }


    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let data : any = formData;
        data[e.target.name] = e.target.value;
        setFormData(data);
    }

    return (
        <Container fluid className="business-invite page">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12}>
                            <h1>
                                Registrar Socio
                            </h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Row className="justify-content-md-center py-5">
                <Col md={6} sm={12} xs={12}>
                    <Form onSubmit={onSubmit}>
                        <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextEmail">
                            <Form.Label column sm="2" className="text-end">
                                Email
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="email" name="email" placeholder="" required onChange={onChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextEmail">
                            <Form.Label column sm="2" className="text-end">
                                Nombre del negocio
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="text" name="name" placeholder="" required onChange={onChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextEmail">
                            <Form.Label column sm="2" className="text-end">
                                Teléfono de contacto (interno)
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="input" name="phone" placeholder="" required onChange={onChange} />
                            </Col>
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3 py-0" controlId="formbutton">
                                    <Col sm={{ span: 10, offset: 2 }}>
                                        <Button type="submit"
                                            className="btn-custom-pink btn-clock w-100 my-2">
                                            Registrar
                                        </Button>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}
export default InviteBusiness;