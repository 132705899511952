import React, { useEffect } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import { request } from '../../services/Request';
import { login } from '../../services/auth';
import { useParams } from 'react-router-dom';
import { ResponseResult, RouteParam } from '../../types';
import Swal from 'sweetalert2';
import '../../styles/page.scss';

const Confirm = () => {
    const { token } = useParams<RouteParam>();
    useEffect(()=>{
        request('/api/auth/confirm',{
            headers:{
                'Authorization': 'Bearer '+token
            },
            noRedirect:true
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                login(result.data);
                window.location.href = "/perfil";
            }else{
                Swal.fire("Error", "Ocurrió un error al verificar tu usuario", "error");
            }
        }).catch((err)=>{
            Swal.fire("Error", "Ocurrió un error al verificar tu usuario", "error");
        })

    });

    return (
        <Container fluid className="page confirm">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12}>
                            <h1>
                                Confirmando usuario
                            </h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Row>
                <Col>
                    <p>Espere mientras confirmamos su usuario...</p>
                </Col>
            </Row>
        </Container>
    )
}
export default Confirm;