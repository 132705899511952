import React, {useEffect, useState} from 'react';
import { Row, Col } from 'react-bootstrap';
import { OwnedCoupon, ResponseResult } from '../types';
import Navigation from '../components/Navigation';
import AcocenIcon from '../assets/acocen-icon.svg';
import '../styles/page.scss';
import CouponCard from '../components/CouponCard';
import {request} from '../services/Request';

function MyCoupons(){

    const [coupons, setCoupons] = useState<OwnedCoupon[]>([]);

    useEffect(()=>{
        request('/api/coupons/mine',{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setCoupons(result.data);
            }
        })
    },[]);

    return (
        <div className="places page">
            <div className="header">
                <div className="overlay"></div>
                <img src={AcocenIcon} className="site-icon" alt="logo" />
                <div className="title">
                    <Row>
                        <Col xs={12} md={{span:10,offset:1}}>
                            <h1>Mi cupones</h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="content">
                <Row>
                    <Col xs={12} md={{span:10,offset:1}}>
                        <Navigation pages={['Mis cupones']}></Navigation>
                        <Row>
                            {
                             coupons.map((item:OwnedCoupon, index:number)=>(
                                    <React.Fragment>
                                        {
                                            item.coupon && (
                                                <Col xs={12} md={4} key={`coupon-${index}`}>
                                                    <CouponCard coupon={item.coupon} status={item.status} url={item.url}/>
                                                </Col>
                                            )
                                        }
                                    </React.Fragment>
                                ))
                            }
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default MyCoupons;