import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { request } from '../../services/Request';
import { ResponseResult } from '../../types';

interface FormData {
    name: string;
    image: string;
}

interface Props {
    onClose: ()=>void;
    businessId: string;
}

function NewPoduct(props: Props) {
    const { onClose, businessId } = props;

    const initialForm : FormData = { name: '', image: ''};
    const [formData,setFormData] = useState<FormData>(initialForm);

    const onCancel = () => {
        setFormData(initialForm);
        onClose();
    }

    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        const fdata = new FormData();
        fdata.append("image",formData?.image);
        fdata.append("name",formData?.name);
        fdata.append("business",businessId);
        request('/api/products',{
            method: 'POST',
            body: fdata,
            file: true,
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                setFormData(initialForm);
                Swal.fire("Producto", "El registro se ha guardado correctamente",'success');
                onClose();
            }else{
                Swal.fire("Producto", "Error a crear registro",'error');
            }
        })
    }


    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let data : any = formData;
        data[e.target.name] = e.target.files ? e.target.files[0] : e.target.value;
        setFormData(data);
    }

    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col>Nuevo producto</Col>
            </Row>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formInputName">
                <Form.Label column sm="2" className="text-end">
                    Nombre
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="text" name="name" placeholder="" required onChange={onChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formInputImage">
                <Form.Label column sm="2" className="text-end">
                    Imagen
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="file" name="image" accept="image/jpg, image/jpeg" placeholder="" required onChange={onChange} />
                    <small>Solo se aceptan imagenes de tipo jpg o jpeg</small>
                </Col>
                <Form.Label column sm={{span:10,offset:2}} className="mt-2">
                    <small>* Dimensiones recomendadas de 700px largo x 400px ancho o radios similares.</small>
                </Form.Label>
            </Form.Group>
            <Row>
                <Col>
                    <Form.Group className="mb-3 py-0" controlId="formbutton">
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit"
                                className="btn-custom-pink btn-clock my-2">
                                Guardar
                            </Button>
                            <Button
                                className="btn-custom-blue ms-5 my-2"
                                onClick={onCancel}
                                >
                                Cancelar
                            </Button>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    )
}

export default NewPoduct;