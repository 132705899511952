import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { getNotices } from '../../redux/noticesSlice';
import { request } from '../../services/Request';
import { ResponseResult } from '../../types';
import { useDispatch } from 'react-redux';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

interface FormData {
    title: string,
    description: string,
}

interface Props {
    onClose: ()=>void;
}

function NewNotice(props: Props) {
    const { onClose } = props;

    const initialForm : FormData = { title: '', description: '', };
    const [formData,setFormData] = useState<FormData>(initialForm);
    const [selectedFiles, setSelectedFiles]:any = useState([]);
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const dispatch = useDispatch();

    const onCancel = () => {
        setFormData(initialForm);
        onClose();
    }

    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        Swal.fire("Aviso", "Guardando...");
        Swal.showLoading();
        const rawContentState = convertToRaw(editorState.getCurrentContent());

        const fdata = new FormData();
        fdata.append("title",formData?.title);
        fdata.append("description", draftToHtml(rawContentState));
        for(let file of selectedFiles){
            fdata.append("newattachment", file);
        }
        request('/api/notices',{
            method: 'POST',
            body: fdata,
            file: true
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setFormData(initialForm);
                dispatch(getNotices());
                Swal.fire("Avisos", "El registro se ha guardado correctamente",'success');
                onClose();
            }else{
                Swal.fire("Avisos", "Error al crear el aviso",'error');
            }
        })
    }


    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let data : any = formData;
        data[e.target.name] = e.target.value;
        setFormData({...data});
    }

    const onChangeFiles = (e:React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFiles( e.target.files );
    }    

    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col>Nuevo aviso</Col>
            </Row>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextName">
                <Form.Label column sm="2" className="text-end">
                    Título
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="text" name="title" placeholder="" required onChange={onChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextName">
                <Form.Label column sm="2" className="text-end">
                    Descripción
                </Form.Label>
                <Col sm="10">
                    <Editor 
                        editorState={editorState} 
                        onEditorStateChange={setEditorState} 
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'colorPicker', 'link', 'embedded', 'remove', 'history']
                        }}
                        />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextImage">
                <Form.Label column sm="2" className="text-end">
                    Adjuntos
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="file" name="newattachment" multiple={true} placeholder="" onChange={onChangeFiles} />
                </Col>
            </Form.Group>
            <Row>
                <Col>
                    <Form.Group className="mb-3 py-0" controlId="formbutton">
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit"
                                className="btn-custom-pink btn-clock my-2">
                                Guardar
                            </Button>
                            <Button
                                className="btn-custom-blue ms-5 my-2"
                                onClick={onCancel}
                                >
                                Cancelar
                            </Button>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    )
}

export default NewNotice;