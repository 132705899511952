import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { getNotices } from '../../redux/noticesSlice';
import { request } from '../../services/Request';
import { Notice, ResponseResult } from '../../types';
import { useDispatch } from 'react-redux';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';

interface Props {
    onClose: ()=>void;
    notice: Notice
}
function EditCategoryForm(props: Props) {
    const { onClose, notice } = props;
    const [formData,setFormData] = useState<Notice>({...notice});
    const [selectedFiles, setSelectedFiles]:any = useState({
        files:[]
    });
    const dispatch = useDispatch();
    const onCancel = () => {
        onClose();
    }
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    useEffect(()=>{
        setEditorState( EditorState.createWithContent(stateFromHTML(formData.description)) );
    },[formData]);

    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        Swal.fire("Editar aviso", "Guardando cambios...");
        Swal.showLoading();
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const fdata = new FormData();
        fdata.append("title",formData?.title);
        fdata.append("description", draftToHtml(rawContentState));
        for(let attachment of formData.attachments){
            fdata.append("attachments", attachment);
        }
        for(let file of selectedFiles.files){
            fdata.append("newattachment", file);
        }
        request(`/api/notices/${formData._id}`,{
            method: 'PUT',
            body: fdata,
            file: true
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                dispatch(getNotices());
                Swal.fire("Avisos", "El registro se ha actualizado correctamente",'success');
                onClose();
            }
            else{
                Swal.fire("Avisos", "Error al editar el aviso",'error');
            }
        })
    };
    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let data : any = formData;
        data[e.target.name] = e.target.value;
        setFormData(data);
    }

    const onChangeFiles = (e:React.ChangeEvent<HTMLInputElement>) => {
        let data = selectedFiles;
        if(e.target.files){
            const files:any = e.target.files;
            for(let file of files){
                data.files.push(file);
            }
            setSelectedFiles({ ...data });
        }
    }

    const removeAttachment = (key:number) =>{
        let data = formData;
        let files = [...data.attachments]
        files.splice(key,1);
        data.attachments = files;
        setFormData({...data});
    }

    const removeNewAttachment = (key:number) =>{
        const data = selectedFiles;
        data.files.splice(key,1);
        setSelectedFiles({...data});
    }

    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col>Editar aviso</Col>
            </Row>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextEmail">
                <Form.Label column sm="2" className="text-end">
                    Nombre
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="text" defaultValue={formData.title} name="title" placeholder="" required onChange={onChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextName">
                <Form.Label column sm="2" className="text-end">
                    Descripción
                </Form.Label>
                <Col sm="10">
                    <Editor 
                        editorState={editorState} 
                        onEditorStateChange={setEditorState} 
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'colorPicker', 'link', 'embedded', 'remove', 'history']
                        }}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextImage">
                <Form.Label column sm="2" className="text-end">
                    Adjuntos
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="file" name="newattachment" multiple={true} placeholder="" onChange={onChangeFiles} />
                    <small>* los nuevos adjuntos reemplazarán los anteriores</small>
                    {
                        notice.attachments && (
                            <div className="mt-1">
                                <hr/>
                                <p><b>Adjuntos actuales:</b></p>
                                <ul>
                                    {
                                        formData.attachments?.map((item:string,key:number)=>(
                                            <li className="mt-1" key={key}><a href={item} target="_blank" rel="noreferrer">Descargar {key+1}</a> <button type="button" className="btn btn-danger" onClick={() => removeAttachment(key)}>Eliminar</button></li>
                                        ))
                                    }
                                </ul>
                                <p><b>Adjuntos a agregar:</b></p>
                                <ul>
                                    {
                                        selectedFiles.files.map((file:File,key:number)=>(
                                            <li className="mt-1" key={key}>{file.name} <button type="button" className="btn btn-danger" onClick={() => removeNewAttachment(key)}>Eliminar</button></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )
                    }
                </Col>
            </Form.Group>
            <Row>
                <Col>
                    <Form.Group className="mb-3 py-0" controlId="formbutton">
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit"
                                className="btn-custom-pink btn-clock my-2">
                                Guardar
                            </Button>
                            <Button
                                onClick={onCancel}
                                className="btn-custom-blue ms-5 my-2"
                                >
                                 Cancelar
                                </Button>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    )
}
export default EditCategoryForm;