import React, { useState } from 'react';
import { Container, Row, Col, Button, Form} from 'react-bootstrap';
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import {request} from '../../services/Request';
import {ResponseResult} from '../../types';
import Swal from 'sweetalert2';
import '../../styles/register.scss';
import '../../styles/page.scss';

interface FormData {
    name?: string,
    phone?: string,
    email?: string,
    password?: string,
}

const Register = () => {
    const [showPassword,setShowPassword] = useState(false);
    const [isDisabledSubmit,setIsDisabledSubmit] = useState(false);
    const [formData,setFormData] = useState<FormData>({})

    const tooglePassowrd = () => {
        setShowPassword(!showPassword)
    }


    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        setIsDisabledSubmit(true);
        Swal.fire('Registro', 'Enviando petición...');
        Swal.showLoading();
        request('/api/auth/signup',{
            method: 'POST',
            body: JSON.stringify(formData)
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                window.location.href = "/registro-completo";
            }else if(result.error.status === 420){
                setIsDisabledSubmit(false);
                Swal.fire({
                    title: "Ingresar",
                    html: 'Su correo <b>"'+formData.email+'"</b> ya fue registrado. Si quiere recuperar su cuenta, presione la opción <b>"Recuperar contraseña"</b>',
                    showCancelButton: true,
                    confirmButtonText: 'Recuperar contraseña',
                    cancelButtonText: 'Cancelar',
                })
                .then((result:any)=>{
                    if(result.isConfirmed){
                        sendEmail();
                    }
                });
            }else{
                Swal.fire("Registrar", "Ocurrió un error al registrar");
                setIsDisabledSubmit(false);
            }
        }).catch((error)=>{
            Swal.fire("Registrar", "Ocurrió un error al registrar");
            setIsDisabledSubmit(false);
        })
    }

    const sendEmail = () => {
        Swal.fire('Recuperar contraseña', 'Enviando petición...');
        Swal.showLoading();
        request('/api/auth/request',{
            method: 'POST',
            body: JSON.stringify(formData)
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                Swal.fire('Recuperar contraseña','Se ha enviado un correo a su cuenta para continuar con el proceso.','success');
            }else{
                Swal.fire('Recuperar contraseña', 'Ocurrió un error con la petición', 'error');
            }
        })
    }


    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let data : any = formData;
        data[e.target.name] = e.target.value;
        setFormData(data);
    }

    return (
        <Container fluid className="page register">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12}>
                            <h1 className="ms-5 display-3">
                                Regístrate
                            </h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Row className="justify-content-md-center py-5">
                <Col md={6} sm={12} xs={12}>
                    <Form onSubmit={onSubmit}>
                        <Form.Group as={Row} className="mb-3 py-1" controlId="formPlaintextEmail">
                            <Form.Label column sm="2" className="text-end" >
                                Nombre
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="text" name="name" placeholder="" required onChange={onChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextEmail">
                            <Form.Label column sm="2" className="text-end" >
                                Teléfono
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="text" placeholder="" required name="phone" onChange={onChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextEmail">
                            <Form.Label column sm="2" className="text-end" >
                                Email
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="email" placeholder="" required name="email" onChange={onChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3 py-2" controlId="formPlaintextPassword">
                            <Form.Label column sm="2" className="text-end">
                                Contraseña
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    className="input-password"
                                    type={ showPassword ? `text` : `password`}
                                    placeholder=""
                                    required
                                    name="password"
                                    onChange={onChange}
                                    />
                                { showPassword && (
                                    <BsFillEyeSlashFill className="eye" onClick={tooglePassowrd} />
                                )}
                                { !showPassword && (
                                    <BsFillEyeFill className="eye" onClick={tooglePassowrd} />
                                )}
                            </Col>
                        </Form.Group>
                        <Row>
                            <Form.Group className="mb-3 py-2" controlId="formHorizontalCheck">
                                <Col sm={{ span: 10, offset: 2 }}>
                                    <Form.Check label="Acepto los Términos, Condiciones y Politicas de Privacidad" required />
                                </Col>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3 py-0" controlId="formbutton">
                                    <Col sm={{ span: 10, offset: 2 }}>
                                        <Button
                                            type="submit"
                                            disabled={isDisabledSubmit}
                                            className="btn-custom-pink btn-clock w-100 my-2"
                                            >
                                            Crear cuenta
                                        </Button>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group className="mb-3 py-0" controlId="formenlaces">
                                    <Col sm={{ span: 10, offset: 2 }}>
                                        <div className="col-sm-12 pt-3 text-center">
                                            <p><a href="/login">¿Ya tienes cuenta? Entra</a></p>
                                        </div>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>

                </Col>
            </Row>
        </Container>
    )
}
export default Register;