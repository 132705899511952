import React from 'react';
import { Container } from 'react-bootstrap';
import '../../styles/page.scss';

function RegisterCompleted() {
    return(
      <div className="register-completed-container page text-center mt-3">
        <Container className="content">
            <h1>
            Registro exitoso.
            </h1>
            <h4 className="text-center">
              En breve recibirás un correo electrónico para confirmar su dirección de email, hasta entonces podrá utilizar su cuenta.
            </h4>
        </Container>
      </div>
    );
}

export default RegisterCompleted;
