import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../styles/page.scss';

function Panel(){
    return (
        <Container fluid className="admin-panel page">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12}>
                            <h1>
                                Administración
                            </h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Container className="content">
                <Row>
                    <Col xs={12} md={3}>
                        <a className="btn btn-custom-pink px-5 w-100 mb-3" href="/admin/categorias">Categorias</a>
                    </Col>
                    <Col xs={12} md={3}>
                        <a className="btn btn-custom-pink px-5 w-100 mb-3" href="/admin/directorio">Directorio</a>
                    </Col>
                    <Col xs={12} md={3}>
                        <a className="btn btn-custom-pink px-5 w-100 mb-3" href="/admin/eventos">Eventos</a>
                    </Col>
                    <Col xs={12} md={3}>
                        <a className="btn btn-custom-pink px-5 w-100 mb-3" href="/admin/lugares">Lugares</a>
                    </Col>
                    <Col xs={12} md={3}>
                        <a className="btn btn-custom-pink px-5 w-100 mb-3" href="/admin/administradores">Administradores</a>
                    </Col>
                    <Col xs={12} md={3}>
                        <a className="btn btn-custom-pink px-5 w-100 mb-3" href="/admin/usuarios">Usuarios</a>
                    </Col>
                    <Col xs={12} md={3}>
                        <a className="btn btn-custom-pink px-5 w-100 mb-3" href="/admin/avisos">Avisos</a>
                    </Col>
                </Row>
            </Container>

        </Container>
    )
}

export default Panel;