import React, {useEffect, useState} from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { Coupon, ResponseResult } from '../types';
import Navigation from '../components/Navigation';
import AcocenIcon from '../assets/acocen-icon.svg';
import {formatCouponDate} from '../utils/date';
import '../styles/page.scss';
import {request} from '../services/Request';

function MyCoupons(){

    const [coupons, setCoupons] = useState<Coupon[]>([]);

    useEffect(()=>{
        request('/api/coupons/statistics',{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setCoupons(result.data);
            }
        })
    },[]);

    return (
        <div className="places page">
            <div className="header">
                <div className="overlay"></div>
                <img src={AcocenIcon} className="site-icon" alt="logo" />
                <div className="title">
                    <Row>
                        <Col xs={12} md={{span:10,offset:1}}>
                            <h1>Estadísticas de cupones</h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="content">
                <Row>
                    <Col xs={12} md={{span:10,offset:1}}>
                        <Navigation pages={['Mis cupones']}></Navigation>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Producto</th>
                                    <th>Comienza</th>
                                    <th>Termina</th>
                                    <th>Total de guardados</th>
                                    <th>Total de aplicados</th>
                                </tr>
                            </thead>
                            <tbody>
                                { coupons.map((item:Coupon, key:number)=>(
                                    <tr key={key}>
                                        <td>{ item.product }</td>
                                        <td>{ formatCouponDate(item.starts) }</td>
                                        <td>{ formatCouponDate(item.ends) }</td>
                                        <td>{ item.user_count }</td>
                                        <td>{ item.user_count_applied }</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default MyCoupons;