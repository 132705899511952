import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { User } from '../types';
import { request } from '../services/Request';
import { ResponseResult } from '../types';

// Define a type for the slice state
export interface UserState {
    data: User[],
    loading: boolean,
    error: string
  }

  // Define the initial state using that type
  const initialState: UserState = {
    data: [],
    loading: false,
    error: ''
  }

export const getUsers = createAsyncThunk(
  'users/getUsers',
  async () => {
    const response : ResponseResult = await request('/api/auth/users');
    return response.data;
  }
)

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder.addCase(getUsers.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getUsers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      });
      builder.addCase(getUsers.rejected, (state, action) => {
        state.loading = false;
        if(action.payload){
          state.error = 'failed';
        } else {
          state.error = action.error.message || '';
        }
      });
    },
  })

  export default usersSlice.reducer