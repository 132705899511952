import { useState } from 'react';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import EditCategoryForm from '../../components/admin/EditCategoryForm';
import NewCategory from '../../components/admin/NewCategory';
import { RootState } from '../../redux/store';
import '../../styles/page.scss';
import Swal from 'sweetalert2';
import { request } from '../../services/Request';
import { Category, ResponseResult } from '../../types';
import { getCategories } from '../../redux/categoriesSlice';
import { useDispatch } from 'react-redux';

const Categories = () => {

    const dispatch = useDispatch();
    const categories = useSelector((state: RootState) => state.categories);
    const [showNewForm,setShowNewForm] = useState(false);
    const [showEditForm,setShowEditForm] = useState(false);
    const [categoryToEdit,setCategoryToEdit] = useState<Category | null>(null);
    const toggleNewForm = () => {
        setShowNewForm(!showEditForm);
    }
    const toggleEditForm = () => {
        setShowEditForm(!showEditForm);
    }
    const hideNewForm = () => {
        setShowNewForm(false);
    }
    const hideEditForm = () => {
        setShowEditForm(false);
    }
    const deleteCategory = (_id:string) => {
        Swal.fire({
            title: '¿Estas seguro?',
            text: "No podras revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then((result) => {
            if (result.isConfirmed) {
                request(`/api/categories/${_id}`,{
                    method: 'DELETE'
                })
                .then((result:ResponseResult)=>{
                    if(result.data){
                        dispatch(getCategories());
                        Swal.fire(
                            'Eliminado!',
                            'La categoria ha sido eliminada',
                            'success'
                        )
                    }else{
                        Swal.fire('Categorías', 'No se pudo eliminar la categoría', 'error');
                    }
                });
            }
          })
    }
    return (
        <Container fluid className="admin-categories page">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12}>
                            <h1>
                                Categorias
                            </h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Container className="content-center">
                <Row className="justify-content-md-center py-5">
                    <Col md={6} xs={12}>
                        {showNewForm && (
                            <NewCategory onClose={hideNewForm} />
                        )}
                        {showEditForm && categoryToEdit && (
                            <EditCategoryForm onClose={hideEditForm} category={categoryToEdit} />
                        )}
                        {!showNewForm && (
                            <Button className="btn-custom-pink-outline-light px-5" onClick={toggleNewForm}>Crear categoria</Button>
                        )}
                    </Col>
                </Row>
                <Row className="justify-content-md-center py-5">
                    <Col md={6} xs={12}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Categoria</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                { categories.loaded && categories.data.map((item:Category, key:number)=>(
                                    <tr key={key}>
                                        <td>{ item.name }</td>
                                        <td>
                                            <Button
                                                onClick={()=>{hideNewForm();toggleEditForm();setCategoryToEdit(item);}}
                                                className="btn-custom-pink-outline-light px-5"
                                                >
                                                    Editar
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                onClick={()=>{deleteCategory(item._id)}}
                                                className="btn-custom-pink px-5"
                                                >
                                                    Eliminar
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
export default Categories;
