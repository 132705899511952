import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import ProfilePicture from '../components/business/ProfilePicture';
import { useParams } from 'react-router-dom';
import { Business, Coupon, Product, ResponseResult, RouteParam, Video } from '../types';
import '../styles/page.scss';
import '../styles/business-detail.scss';
import { request } from '../services/Request';
import Swal from 'sweetalert2';
import Phone from '../assets/tel_white.svg';
import Web from '../assets/web_white.svg';
import Location from '../assets/location-ico-white.svg';
import Facebook from '../assets/facebook-ico-white.svg';
import Instagram from '../assets/instagram-ico-white.svg';
import Youtube from '../assets/youtube-ico-white.svg';
import BusinessProduct from '../components/business/BusinessProduct';
import CouponCard from '../components/CouponCard';
import MapMarker from '../assets/map_marker.svg';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

function BusinessDetail(){
    const { slug } = useParams<RouteParam>();
    const [business,setBusiness] = useState<Business | null>(null);
    const [products,setProducts] = useState<Product[]>([]);
    const [coupons,setCoupons] = useState<Coupon[]>([]);

    useEffect(()=>{
        request(`/api/businesses/slug/${slug}`)
            .then((result:ResponseResult)=>{
                if(result.data){
                    setBusiness(result.data);
                }else{
                    Swal.fire("Negocio", "Ocurrió un error al obtener negocio");
                }
            });
    },[slug]);

    useEffect(()=>{
        if(business){
            request(`/api/products/business/${business._id}`,{
                method: 'get'
            }).then((result:ResponseResult)=>{
                if(result.error){
                    Swal.fire("Actualizar", "Ocurrió un error al cargar los productos");
                } else {
                    setProducts(result.data);
                }
            });
            request(`/api/coupons/business/${business._id}`,{
                method: 'get'
            }).then((result:ResponseResult)=>{
                if(result.error){
                    Swal.fire("Actualizar", "Ocurrió un error al cargar los productos");
                } else {
                    setCoupons(result.data);
                }
            });
        }
    },[business])

    const containerStyle = {
        width: '100%',
        height: '600px',
    }

    // https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
    const mapOptions = {
        streetViewControl: false,
        styles: [
            {
                featureType: "poi.business",
                stylers: [{ visibility: "off" }]
            }
        ]
    }

    const center = {
        lat: 21.8842646,
        lng: -102.3027221
    }

    const getVideoId = (item:Video) => {
        return item.url.indexOf('watch') > 0 ? item.url.replace("https://www.youtube.com/watch?v=", "") : item.url.replace("https://youtu.be/", "");
    }

    return (
        <div className="business-details page">
            { business && (
                <Container className="content">
                    <Row>
                        <Col xs={12} md={{span:10,offset:1}} className="d-flex justify-content-center mb-3">
                            <ProfilePicture src={business.profile_picture} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-center">
                            <h3>
                                {business.name}
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-center mb-5">
                            <span>
                                {business.description}
                            </span>
                        </Col>
                    </Row>
                    <Row className="bg-blue">
                        <Col xs={12} md={4} className="my-3">
                            <a href={`tel:${business.phone}`} target="_blank" rel="noreferrer" className="d-flex align-items-stretch">
                                <img src={Phone} alt="teléfono" className="me-3" /> <span className="d-flex align-items-center">{business.phone}</span>
                            </a>
                        </Col>
                        { business.website && (
                            <Col xs={12} md={4} className="my-3">
                                <a href={business.website} target="_blank" rel="noreferrer" className="d-flex align-items-stretch">
                                    <img src={Web} alt="web" className="me-3" /> <span className="d-flex align-items-center">{business.website}</span>
                                </a>
                            </Col>
                        )}
                        { business.facebook && (
                            <Col xs={12} md={12} className="justify-content-center my-3">
                                <a href={business.facebook} target="_blank" rel="noreferrer" className="d-flex align-items-stretch">
                                    <img src={Facebook} alt="facebook" className="me-3" /> <span className="d-flex align-items-center">{business.facebook}</span>
                                </a>
                            </Col>
                        )}
                        { business.instagram && (
                            <Col xs={12} md={12} className="justify-content-center my-3">
                                <a href={business.instagram} target="_blank" rel="noreferrer" className="d-flex align-items-stretch">
                                    <img src={Instagram} alt="instagram" className="me-3" /> <span className="d-flex align-items-center">{business.instagram}</span>
                                </a>
                            </Col>
                        )}
                        { business.youtube && (
                            <Col xs={12} md={12} className="justify-content-center my-3">
                                <a href={business.youtube} target="_blank" rel="noreferrer" className="d-flex align-items-stretch">
                                    <img src={Youtube} alt="youtube" className="me-3" /> <span className="d-flex align-items-center">{business.instagram}</span>
                                </a>
                            </Col>
                        )}
                        { business.location && business.address && (
                            <Col xs={12} md={12} className="justify-content-center my-3">
                                <a
                                    href={`https://www.google.com/maps/search/?api=1&query=${business.location?.lat},${business.location?.lng}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="d-flex align-items-stretch"
                                    >
                                        <img src={Location} alt="ubicación" className="me-3" /> <span className="d-flex align-items-center">{business.address}</span>
                                </a>
                            </Col>
                        )}
                    </Row>
                    { products.length > 0 && (
                        <>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center my-5">
                                    <h3>Nuestros Productos</h3>
                                </Col>
                            </Row>
                            <Row>
                                {products.map((item:Product,key:number)=>(
                                    <Col key={`product-${key}`} md={4} className="mt-2">
                                        <BusinessProduct product={item}/>
                                    </Col>
                                ))}
                            </Row>
                        </>
                    )}
                    { business.videos.length > 0 && (
                        <>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center my-5">
                                    <h3>Videos</h3>
                                </Col>
                            </Row>
                            <Row>
                                {business.videos.map((item:Video,key:number)=>(
                                    <Col key={`video-${key}`} md={4} className="mt-2">
                                        <div className="video-responsive">
                                            <iframe src={"https://www.youtube.com/embed/"+ getVideoId(item)} allowFullScreen></iframe>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </>
                    )}
                    { coupons.length > 0 && (
                        <>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center my-5">
                                    <h3>Promociones</h3>
                                </Col>
                            </Row>
                            <Row>
                                {coupons.map((item,key)=>(
                                    <Col key={`coupon-${key}`} md={4}>
                                        <CouponCard coupon={item} />
                                    </Col>
                                ))}
                            </Row>
                        </>
                    )}
                    { business.location && (
                        <Row className="mt-3">
                            <Col>
                                <LoadScript
                                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
                                >
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={center}
                                        zoom={16}
                                        options={mapOptions}
                                        >
                                            <Marker
                                                icon={MapMarker}
                                                position={business.location}
                                                animation={2}
                                            />
                                    </GoogleMap>
                                </LoadScript>
                            </Col>
                        </Row>
                    )}
                </Container>
            )}
        </div>
    )
}

export default BusinessDetail;
