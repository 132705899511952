import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import { ResponseResult, Coupon } from '../../types';
import Swal from 'sweetalert2';
import { request } from '../../services/Request';
import NewCoupon from './NewCoupon';
import CouponCard from '../CouponCard';
import EditCoupon from './EditCoupon';
interface IProps {
    businessId: string;
}
const Coupons = (props: IProps) => {

    const [showEditForm,setShowEditForm] = useState(false);
    const [couponToEdit,setCouponToEdit] = useState<Coupon | null>(null);
    const [coupons,setCoupons] = useState<Coupon[] | null>(null);
    const [showNewForm,setShowNewForm] = useState(false);
    const { businessId } = props;

    const toggleEditForm = () => {
        setShowEditForm(!showEditForm);
    }
    const hideEditForm = () => {
        setShowEditForm(false);
    }
    const getCoupons = useCallback(()=>{
        request(`/api/coupons/business/${businessId}`,{
            method: 'get'
        }).then((result:ResponseResult)=>{
            if(result.error){
                Swal.fire("Actualizar", "Ocurrió un error al cargar los cupones");
            } else {
                setCoupons(result.data);
            }
        });
    }, [businessId]);

    const deleteCoupon = (_id:string) => {
        Swal.fire({
            title: '¿Estas seguro?',
            text: "No podras revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
          }).then((result) => {
            if (result.isConfirmed) {
                request(`/api/coupons/${_id}`,{
                    method: 'DELETE'}).then((res: ResponseResult)=>{
                        if(res.data){
                            Swal.fire(
                                'Producto',
                                'El producto ha sido eliminado',
                                'success'
                            )
                        getCoupons();
                        }else{
                            Swal.fire(
                                'Producto',
                                'El producto no ha podido ser eliminado',
                                'error'
                            )
                        }
                    })
            }
          })
    }
    
    useEffect(()=>{
        getCoupons();
    },[getCoupons])

    const toggleNewForm = () => {
        setShowNewForm(!showNewForm);
    }
    const hideNewForm = () => {
        setShowNewForm(false);
    }

    return (
        <Container className="business-profile-coupons mt-5 mb-auto py-auto">
            <Row>
                <Col>
                    <div className="fs-2 text-center">Cupones</div>
                </Col>
            </Row>
            <Row className="py-5">
                <Col md={{span:12}}>
                    {showNewForm && (
                        <NewCoupon businessId={businessId} onClose={()=>{hideNewForm();getCoupons();}} />
                    )}
                    {
                        coupons && coupons.length >= 6 && (
                            <Alert variant="warning">
                                ¡Has llegado al límite de cupones!
                            </Alert>
                        )
                    }
                    {!showNewForm && (coupons && coupons.length<6) && (
                        <Button className="btn-custom-pink-outline-light px-5" onClick={toggleNewForm}>Nuevo Cupón</Button>
                    )}
                </Col>
            </Row>
            <Row className="justify-content-md-center py-auto">
                <Col md={{span:12}}>
                    {showEditForm && couponToEdit && (
                        <EditCoupon coupon={couponToEdit} onClose={()=>{hideEditForm();getCoupons();}}/>
                    )}
                </Col>
            </Row>

            {coupons && coupons.length > 0 && (
                <Row>
                    {coupons.map((item,key)=>(
                        <Col key={`coupon-${key}`} md={{span:4}}>
                            <CouponCard coupon={item} />
                                <Button 
                                    onClick={()=>{toggleEditForm();setCouponToEdit(item);}}
                                    className="btn-primary btn-block w-100 my-1">Editar
                                </Button>
                                <Button 
                                     onClick = {() => {deleteCoupon(item._id);}}
                                    className="btn-custom-pink btn-block w-100 mb-5">Eliminar
                                </Button>
                        </Col>
                    ))}
                </Row>
            )}
        </Container>
    )
}
export default Coupons;