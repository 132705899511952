import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Place } from '../types';
import Navigation from '../components/Navigation';
import AcocenIcon from '../assets/acocen-icon.svg';
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store';
import '../styles/page.scss';
import PlaceCard from '../components/PlaceCard';

function Places(){

    const places = useSelector((state: RootState) => state.places)

    return (
        <div className="places page">
            <div className="header">
                <div className="overlay"></div>
                <img src={AcocenIcon} className="site-icon" alt="logo" />
                <div className="title">
                    <Row>
                        <Col xs={12} md={{span:10,offset:1}}>
                            <h1>Puntos de interés</h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="content">
                <Row>
                    <Col xs={12} md={{span:10,offset:1}}>
                        <Navigation pages={['Puntos de Interés']}></Navigation>
                        <Row>
                            {
                             places.data.map((item:Place, index)=>(
                                    <Col xs={12} md={4} key={`place-${index}`}>
                                        <PlaceCard place={item} />
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Places;