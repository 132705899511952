import React from 'react';
import '../styles/page.scss';

function AppliedCoupon() {
    return(
      <div className="NotFound-container page text-center mt-3">
        <h1>
         Cupón aplicado con éxito.
        </h1>
        <p className="text-center">Puedes regresar a la página de <a href="/">Inicio</a></p>
      </div>
    );
}

export default AppliedCoupon;
