import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button} from 'react-bootstrap';
import { logout } from '../../services/auth';
import Swal from 'sweetalert2';
import '../../styles/page.scss';
import { useUser } from '../../hooks/userHook';
import { Business, ResponseResult } from '../../types';
import { request } from '../../services/Request';
import BusinessProfile from '../../components/business/BusinessProfile';

const Profile = () => {
    const user = useUser();
    const [business,setBusiness] = useState<Business | null>(null);

    useEffect(()=>{
        if(user.isLoggedIn && user.data?.business && !business){
            getBusiness();
        }
    });

    const getBusiness = () => {
        const businessId = user.data?.business
        request(`/api/businesses/${businessId}`)
            .then((result:ResponseResult)=>{
                if(result.data){
                    setBusiness(result.data);
                }else{
                    Swal.fire("Negocio", "Ocurrió un error al obtener negocio");
                }
            }).catch(()=>{
                Swal.fire("Negocio", "Ocurrió un error al obtener negocio");
            })
    }

    const onLogout = () => {
        Swal.fire({
            title: '¿Realmente desea cerrar sesión?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
          }).then((result) => {
            if (result.isConfirmed) {
              logout();
            }
          })
    }

    return (
        <Container fluid className="page profile">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12}>
                            <h1>
                                Bienvenido
                            </h1>
                        </Col>
                    </Row>
                </div>
            </div>
            { user.isLoggedIn && (
                <Container className="content">
                    <Row className="py-5 justify-content-end">
                         <Col xs={12} md={4} className="">
                            <Button className="btn-custom-pink-outline-light px-5 mt-2" href={"/CONTRATO_DE_AFILIACIÓN_A_LA_PLATAFORMA_COMERCIAL_AGUAS_VOY_AL_CENTRO.pdf"} target="_blank" rel="noreferrer">Condiciones de afiliación</Button>
                        </Col>
                        <Col xs={12} md={3} className="">
                            <Button className="btn-custom-pink-outline-light px-5 mt-2" onClick={onLogout}>Cerrar Sesión</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Nombre
                        </Col>
                        <Col>
                            { user.data?.name }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Email
                        </Col>
                        <Col>
                            { user.data?.email }
                        </Col>
                    </Row>
                    { user.data?.business && business && (
                        <BusinessProfile business={business} onUpdate={getBusiness} />
                    )}
                </Container>
            )}
        </Container>
    )
}
export default Profile;