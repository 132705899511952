
import React, { useEffect, useCallback } from "react";
import { Row, Col, Container, Table } from 'react-bootstrap';
import { User, ResponseResult } from '../../types';
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store';
import '../../styles/page.scss';
import { getUsers } from '../../redux/usersSlice';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { request } from '../../services/Request';

function Users(){

    const users = useSelector((state: RootState) => state.users);
    const dispatch = useDispatch();

    const refresh = useCallback(() => {
        dispatch(getUsers());
    },[dispatch])

    useEffect(()=>{
        refresh();
    },[refresh]);

    const removeItem = (item:User) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar el usuario '+item.name+'?',
            text: "No podrás revertir este cambio",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar!',
            cancelButtonText: 'No'
        }).then((result:any) => {
            if (result.isConfirmed) {
                request(`/api/auth/user/${item._id}`,{
                    method: 'DELETE'
                })
                .then((result:ResponseResult)=>{
                    if(result.data){
                        dispatch(getUsers());
                        Swal.fire(
                            'Usuario',
                            'El usuario ha sido eliminado',
                            'success'
                        )
                    }else{
                        Swal.fire('Usuario', "No se pudo eliminar el usuario", "error");
                    }
                })
            }
          })
    }

    const resendInvitation = (item:User) => {
        Swal.fire("Reenviar invitación", "Realizando petición...");
        Swal.showLoading();
        request(`/api/auth/resend`,{
            method: 'POST',
            body: JSON.stringify({
                user: item._id
            })
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                Swal.fire(
                    'Reenviar invitación',
                    'La invitación se ha enviado con éxito',
                    'success'
                )
            }else{
                Swal.fire('Reenviar invitación', "No se pudo enviar la invitación", "error");
            }
        })
    }


    return (
        <Container fluid className="usuarios page">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12} md={{span:10,offset:1}}>
                            <h1>Usuarios</h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Container className="content">
                <Row>
                    <Col xs={12} md={{span:12,offset:0}}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Email</th>
                                    <th>Teléfono</th>
                                    <th>Estado</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                { users.data.map((item:User)=>(
                                        <tr>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.status ? 'Activo': 'Sin confirmar'}</td>
                                            <td>
                                                <button className="btn btn-danger" onClick={() => removeItem(item)}>Eliminar</button>
                                                <button className="btn btn-secondary ms-1" onClick={() => resendInvitation(item)} title="Reenviar invitación">Reenviar</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Users;