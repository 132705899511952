import React from 'react';
import { Product } from '../../types';
import '../../styles/product.scss';

interface IProps {
    product: Product;
}

const BusinessProduct = (props: IProps) => {

    const { product } = props;

    return (
        <div className="product">
            <div className="image" style={{backgroundImage: `url(${product.image})`}}>
            </div>
            <div className="name">
                { product.name }
            </div>
        </div>
    )

}


export default BusinessProduct;