import React, {useEffect,useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Navigation from '../../components/Navigation';
import '../../styles/page.scss';
import { Notice, RouteParam } from '../../types';
import { useParams } from 'react-router-dom';

const NoticeDetail = () => {

    const { id } = useParams<RouteParam>();
    const notices = useSelector((state: RootState) => state.notices);
    const [notice, setNotice] = useState<Partial<Notice>>({
        title:'',
        description:'',
        attachments:[]
    });

    useEffect(()=>{
        for(let i=0;i<notices.data.length;i++){
            if(notices.data[i]._id === id){
                setNotice(notices.data[i]);
            }
        }
    },[id, notices.data]);

    return (
        <Container fluid className="notice-detail page">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12}>
                            <h1>
                                Avisos
                            </h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Container className="content-center">
                <Row className="align-items-center">
                    <Col xs={12} md={{offset:1,span:2}}>
                        <Navigation pages={['Avisos']}></Navigation>
                    </Col>
                    <Col xs={12} md={{span:2,offset:6}}>
                        <a href="/perfil/Comunicacion/avisos" className="btn btn-custom-white">
                            Regresar
                        </a>
                    </Col>
                </Row>
                <Row className="py-5">
                    <Col md={{offset:1,span:10}} xs={12}>
                        <h2 className="notice-title">
                            {notice.title}
                        </h2>
                        <p className="notice-content">
                            <div className="post__content" dangerouslySetInnerHTML={{__html: notice.description ? notice.description : ''}}></div>
                        </p>

                        {
                            notice.attachments && (
                                <div className="mt-5">
                                    <hr/>
                                    <p><b>Descargar adjuntos:</b></p>
                                    <ul>
                                        {
                                            notice.attachments?.map((item:string,key:number)=>(
                                                <li><a href={item} target="_blank" rel="noreferrer">Descargar {key+1}</a></li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
export default NoticeDetail;
