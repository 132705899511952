import Logo from '../assets/AVC-blanco.svg';
import SocialNetwork from './SocialNetwork';
import AcocenWhite from '../assets/acocen_logo_footer.svg';
import {Row, Col, Navbar} from 'react-bootstrap';
import '../styles/footer.scss';

function Footer(){
    return (
        <div id="footer">
            <Row>
                <Col xs={12} md={2}>
                    <Navbar.Brand href="/">
                        <img src={Logo} alt="logo" />
                    </Navbar.Brand>
                </Col>
                <Col xs={12} md={7}>
                    <div className="submenu">
                        <a href="/">
                            Inicio
                        </a>
                        <a href="/directorio">
                            Directorio
                        </a>
                        <a href="/eventos">
                            Eventos
                        </a>
                        <a href="/lugares">
                            Puntos de interés
                        </a>
                        <a href="/promos">
                            Promociones
                        </a>
                    </div>
                    <div className="mt-5">
                        <Row >
                            <Col xs={12}>
                                <div className="submenu">
                                    <a href="https://www.gob.mx/">
                                        Enlaces federales
                                    </a>
                                    <a href="http://www.aguascalientes.gob.mx/estado/enlaces.html">
                                        Enlaces estatales
                                    </a>
                                    <a href="http://www.aguascalientes.gob.mx/estado/municipios/">
                                        Enlaces municipales
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={12} md={2} className="mt-5 mt-md-0">
                    <SocialNetwork className="justify-content-center" type={"footer"}/>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={{offset:5,span:2}}>
                    <Navbar.Brand href="/">
                        <img src={AcocenWhite} className="main-logo" alt="logo" />
                    </Navbar.Brand>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={{offset:2,span:8}}>
                    <p className="text-center">
                        Contáctanos en <b><a href="mailto://contacto@acocen.com.mx">contacto@acocen.com.mx</a></b> o por teléfono <b>449-1712-334</b> y <b>449-1712-335</b>
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={{offset:2,span:8}}>
                    <p className="text-center">
                        Todos los derechos reservados © 2021 | <a href="https://acocen.com.mx/assets/files/aviso-privacidad.pdf" target="_blank" rel="noreferrer">Aviso de privacidad</a> | <a href={"/CONTRATO_DE_AFILIACIÓN_A_LA_PLATAFORMA_COMERCIAL_AGUAS_VOY_AL_CENTRO.pdf"} target="_blank" rel="noreferrer">Condiciones de afiliación</a>
                    </p>
                </Col>
            </Row>
        </div>
    );
}

export default Footer;