import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Coupon } from '../types';
import Navigation from '../components/Navigation';
import AcocenIcon from '../assets/acocen-icon.svg';
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store';
import '../styles/page.scss';
import CouponCard from '../components/CouponCard';

function Places(){

    const coupons = useSelector((state: RootState) => state.coupons)

    return (
        <div className="places page">
            <div className="header">
                <div className="overlay"></div>
                <img src={AcocenIcon} className="site-icon" alt="logo" />
                <div className="title">
                    <Row>
                        <Col xs={12} md={{span:10,offset:1}}>
                            <h1>Promociones</h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="content">
                <Row>
                    <Col xs={12} md={{span:10,offset:1}}>
                        <Navigation pages={['Promociones']}></Navigation>
                        <Row>
                            {
                             coupons.data.map((item:Coupon, index)=>(
                                    <Col xs={12} md={4} key={`coupon-${index}`}>
                                        <CouponCard coupon={item} />
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Places;