import React, { useState } from 'react';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import MapMarker from '../assets/map_marker.svg';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { RootState } from '../redux/store';
import { useSelector } from 'react-redux';
import { Category } from '../types';
import BusinessCard from './BusinessCard';


const HomeMap = () => {


    const categories = useSelector((state: RootState) => state.categories);
    const businesses = useSelector((state: RootState) => state.businesses);

    const [selectedCategory,setSelectedCategory] = useState("Elige categoría");
    const [listedBusinesses,setListedBusinesses] = useState(businesses.data.filter((business)=>{return business.location}));
    const [businessToShow,setBusinessToShow] = useState('');

    const containerStyle = {
        width: '100%',
        height: '700px',
    }

    // https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
    const mapOptions = {
        streetViewControl: false,
        styles: [
            {
                featureType: "poi.business",
                stylers: [{ visibility: "off" }]
            }
        ]
    }

    const center = {
        lat: 21.8842646,
        lng: -102.3027221
    }

    const filterCategory = (category:string)=>{
        setBusinessToShow("");
        setSelectedCategory(category);
        if(category === "Todos"){
            setListedBusinesses(businesses.data.filter((business)=>{return business.location}));
        } else {
            const toFilter = businesses.data.filter((business)=>{
                return business.location && business.categories && business.categories.includes(category)
            });
            setListedBusinesses(toFilter);
        }
    }

    return (
        <Container className="m-0 p-0" fluid>
            <Row>
                <Col xs={12} md={{span:4,offset:4}}>
                    <Dropdown>
                        <Dropdown.Toggle variant="custom-pink">
                            { selectedCategory }
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={()=>{filterCategory("Elige categoría");}}>Elige categoría</Dropdown.Item>
                            { categories.loaded && categories.data.map((category:Category,key:number)=>(
                                <Dropdown.Item className="ext-capitalize" onClick={()=>{filterCategory(category.name);}} key={`cat-${key}`}>
                                    {category.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            <Row>
                <Col className="m-0 p-0">
                    <LoadScript
                        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
                    >
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={16}
                            options={mapOptions}
                                >
                            { /* Child components, such as markers, info windows, etc. */ }
                            <></>
                            { listedBusinesses.map((business, key)=>(
                                <Marker
                                    icon={MapMarker}
                                    position={business.location}
                                    animation={2}
                                    onClick={()=>{setBusinessToShow(business._id)}}
                                    key={`marker-${key}`}
                                >
                                    { businessToShow === business._id && (
                                        <InfoWindow
                                            onCloseClick={()=>{setBusinessToShow("")}}
                                        >
                                            <div style={{width:'400px'}}>
                                                <BusinessCard business={business} />
                                            </div>
                                        </InfoWindow>
                                    )}
                                </Marker>
                            ))}
                        </GoogleMap>
                    </LoadScript>
                </Col>
            </Row>
        </Container>
    )
}
export default HomeMap;