import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { Event, Place } from '../types';
import '../styles/minihomecarousel.scss';
import EventCard from './EventCard';
import PlaceCard from './PlaceCard';

interface Props {
    data: Event[] | Place[],
    loading: boolean,
}

function MiniHomeCarousel (props: Props){
    const {data, loading} = props;
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex:number, e:any) => {
        setIndex(selectedIndex);
    };

    return (
        <div>
            { loading === true && (
                <div>Cargando eventos</div>
            )}
            { !loading && data && (
                <div className="mini-home-carousel">
                    <Carousel activeIndex={index} onSelect={handleSelect}>
                        {
                            data.map((item:Event|Place,key:number)=>(
                                <Carousel.Item key={`mini-${key}`}>
                                    { 'date' in item && (
                                        <EventCard event={item} />
                                    )}
                                    { !('date' in item) && (
                                        <PlaceCard place={item} />
                                    )}
                                </Carousel.Item>
                            ))
                        }
                    </Carousel>                    
                </div>
            )}
        </div>
    )
}
export default MiniHomeCarousel;