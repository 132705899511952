import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { getCategories } from '../../redux/categoriesSlice';
import { request } from '../../services/Request';
import { ResponseResult } from '../../types';
import { useDispatch } from 'react-redux';
import slugify from 'react-slugify';

interface FormData {
    name: string,
    slug: string,
    icon: string,
}

interface Props {
    onClose: ()=>void;
}

function NewCategory(props: Props) {
    const { onClose } = props;

    const initialForm : FormData = { name: '', slug: '', icon:'' };
    const [formData,setFormData] = useState<FormData>(initialForm);
    const dispatch = useDispatch();

    const onCancel = () => {
        setFormData(initialForm);
        onClose();
    }

    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        request('/api/categories',{
            method: 'POST',
            body: JSON.stringify(formData)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                setFormData(initialForm);
                dispatch(getCategories());
                Swal.fire("Categorias", "El registro se ha guardado correctamente",'success');
                onClose();
            }else{
                Swal.fire("Categorias", "¡Ya existe esta categoria! Intenta usar otro nombre",'error');
            }
        })
    }


    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let data : any = formData;
        data[e.target.name] = e.target.value;
        data.slug = slugify(data.name.toLowerCase());
        setFormData({...data});
    }

    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col>Nueva Categoría</Col>
            </Row>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextName">
                <Form.Label column sm="2" className="text-end">
                    Nombre
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="text" name="name" placeholder="" required onChange={onChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextSlug">
                <Form.Label column sm="2" className="text-end">
                    URL
                </Form.Label>
                <Col sm="10">
                    /directorio/{formData.slug}
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextIcon">
                <Form.Label column sm="2" className="text-end">
                    Ícono
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="text" name="icon" placeholder="Ej. book" required onChange={onChange} />
                    <p><small>(Seleccionar uno de <a href="https://fontawesome.com/v5.15/icons?d=gallery&p=2&m=free">FontAwesome</a>)</small></p>
                </Col>
            </Form.Group>
            <Row>
                <Col>
                    <Form.Group className="mb-3 py-0" controlId="formbutton">
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit"
                                className="btn-custom-pink btn-clock my-2">
                                Guardar
                            </Button>
                            <Button
                                className="btn-custom-blue ms-5 my-2"
                                onClick={onCancel}
                                >
                                Cancelar
                            </Button>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    )
}

export default NewCategory;