import React, { useState } from 'react';
import { Container, Row, Col, Button, Form} from 'react-bootstrap';
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { request } from '../../services/Request';
import '../../styles/login.scss';
import '../../styles/page.scss';
import { ResponseResult } from '../../types';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

interface FormData {
    password2?: string,
    password?: string,
}

interface RouteParam {
    token: string
}

const ChangePassword = () => {
    const [formData,setFormData] = useState<FormData>({})
    const [showPassword,setShowPassword] = useState(false);
    const [showPassword2,setShowPassword2] = useState(false);
    const {token} = useParams<RouteParam>();

    const tooglePassword = () => {
        setShowPassword(!showPassword)
    }

    const tooglePassword2 = () => {
        setShowPassword2(!showPassword2)
    }

    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        if(formData.password !== formData.password2){
            Swal.fire('Cambiar contraseña', 'Las contraseñas no coinciden', 'error');
        }else{
            request('/api/auth/setpassword',{
                method: 'POST',
                headers:{
                    'Authorization': 'Bearer '+token
                },
                body: JSON.stringify(formData)
            })
            .then((result:ResponseResult)=>{
                if(result.data){
                    window.location.href = "/login?password=true";
                }else{
                    Swal.fire("Cambiar contraseña", "Ocurrió un error al cambiar la contraseña", "error");
                }
            })
        }
    }


    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let data : any = formData;
        data[e.target.name] = e.target.value;
        setFormData(data);
    }

    return (
        <Container fluid className="page login">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12}>
                            <h1>
                                Cambiar contraseña
                            </h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Row className="justify-content-md-center py-5">
                <Col md={6} sm={12} xs={12}>
                    <Form onSubmit={onSubmit}>
                        <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextEmail">
                            <Form.Label column sm="2" className="text-end">
                                Ingresa tu contraseña
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    className="input-password"
                                    type={ showPassword ? `text` : `password`}
                                    placeholder=""
                                    name="password"
                                    required onChange={onChange}
                                    />
                                { showPassword && (
                                    <BsFillEyeSlashFill className="eye" onClick={tooglePassword} />
                                )}
                                { !showPassword && (
                                    <BsFillEyeFill className="eye" onClick={tooglePassword} />
                                )}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3 py-2" controlId="formPlaintextPassword">
                            <Form.Label column sm="2" className="text-end">
                                Repite tu contraseña
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    className="input-password"
                                    type={ showPassword2 ? `text` : `password`}
                                    placeholder=""
                                    name="password2"
                                    required onChange={onChange}
                                    />
                                { showPassword && (
                                    <BsFillEyeSlashFill className="eye" onClick={tooglePassword2} />
                                )}
                                { !showPassword && (
                                    <BsFillEyeFill className="eye" onClick={tooglePassword2} />
                                )}
                            </Col>
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3 py-0" controlId="formbutton">
                                    <Col sm={{ span: 10, offset: 2 }}>
                                        <Button type="submit"
                                            className="btn-custom-pink btn-clock w-100 my-2">
                                            Cambiar
                                        </Button>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>

                </Col>
            </Row>
        </Container>
    )
}
export default ChangePassword;