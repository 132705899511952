import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Coupon } from '../types';
import { request } from '../services/Request';
import { ResponseResult } from '../types';

// Define a type for the slice state
export interface CouponsState {
    data: Coupon[],
    loading: boolean,
    error: string
  }

  // Define the initial state using that type
  const initialState: CouponsState = {
    data: [],
    loading: false,
    error: ''
  }

export const getCoupons = createAsyncThunk(
  'coupons/getCoupons',
  async () => {
    const response : ResponseResult = await request('/api/coupons',{});
    return response.data;
  }
)

export const couponsSlice = createSlice({
    name: 'coupons',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder.addCase(getCoupons.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getCoupons.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      });
      builder.addCase(getCoupons.rejected, (state, action) => {
        state.loading = false;
        if(action.payload){
          state.error = 'failed';
        } else {
          state.error = action.error.message || '';
        }
      });
    },
  })

  export default couponsSlice.reducer