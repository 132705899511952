import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { Coupon } from '../types';
import CouponCard from './CouponCard';

function CouponRibbon (){

    const coupons = useSelector((state: RootState) => state.coupons)

    const getRandomCoupons = () => {
        return [...coupons.data].sort(() => 0.5 - Math.random()).slice(0,6);
    }

    return (
        <div>
            { coupons.loading === true && (
                <div>Cargando cupones</div>
            )}
            { !coupons.loading && coupons.data && (
                <Row className="coupon-ribbon">
                    {
                        getRandomCoupons().map((item:Coupon,key:number)=>(
                            <Col key={`coupon-ribbon-${key}`} sm={12} md={4}>
                                <CouponCard coupon={item} />
                            </Col>
                        ))
                    }
                </Row>
            )}
        </div>
    )
}
export default CouponRibbon;