import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { request } from '../../services/Request';
import { ResponseResult } from '../../types';

interface FormData {
    title: string,
    description: string,
    image_url: string,
}

interface Props {
    onClose: ()=>void;
    onSuccess: ()=>void;
}

function NewPlace(props: Props) {
    const { onClose, onSuccess } = props;

    const initialForm : FormData = {
            title: '',
            description:'',
            image_url:'',
        }
    const [formData,setFormData] = useState<FormData>(initialForm)

    const onCancel = () => {
        setFormData(initialForm);
        onClose();
    }

    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        if(formData.description && formData.description.length > 400){
            Swal.fire("Lugar", "La descripción no puede exceder un máximo de 400 caracteres", "error");
        }else{
            const fdata = new FormData();
            fdata.append("title",formData?.title);
            fdata.append("description",formData?.description);
            fdata.append("image_url",formData?.image_url);
    
            request('/api/places',{
                method: 'POST',
                body: fdata,
                file: true,
            })
            .then((result:ResponseResult)=>{
                if(result.data){
                    setFormData(initialForm);
                    onSuccess();
                    Swal.fire("Puntos de Interés", "El registro se ha guardado correctamente",'success');
                    onClose();
                }else{
                    Swal.fire("Puntos de Interés", "Error al crear registro",'error');
                }
            })
        }
    }


    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let data : any = formData;
        data[e.target.name] = e.target.files ? e.target.files[0] : e.target.value;
        setFormData({...data});
    }

    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col sm="2">Nuevo</Col>
            </Row>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextTitle">
                <Form.Label column sm="2" className="text-end">
                    Título
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="text" name="title" placeholder="" required onChange={onChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextDescription">
                <Form.Label column sm="2" className="text-end">
                    Descripción <br/>( {formData.description ? formData.description.length + "/400" : '0/400'} caracteres)
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="text" max="400" name="description" placeholder="" required onChange={onChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextImage">
                <Form.Label column sm="2" className="text-end">
                    Imágen
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="file" name="image_url" accept="image/jpg, image/jpeg" placeholder="" required onChange={onChange} />
                    <small>Solo se aceptan imagenes de tipo jpg o jpeg</small>
                </Col>
            </Form.Group>
            <Row>
                <Col>
                    <Form.Group className="mb-3 py-0" controlId="formbutton">
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit"
                                className="btn-custom-pink btn-clock my-2">
                                Guardar
                            </Button>
                            <Button
                                className="btn-custom-blue ms-5 my-2"
                                onClick={onCancel}
                                >
                                Cancelar
                            </Button>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    )
}

export default NewPlace;