import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Select, { SelectInstance } from 'react-select'
import EditPencil from '../../assets/edit_pencil.svg';

interface IProps {
    categories?: string[];
    onSave: (formData: FormData, onSuccess:()=>void)=>void;
}

interface Option {
    readonly label: string;
    readonly value: string;
  }

const BusinessCategories = (props: IProps) => {

    const { categories = [], onSave } = props;
    const allCategories = useSelector((state: RootState) => state.categories);
    const [options,setOptions] = useState<Option[]>([]);
    const [enableCategoriesEditForm,setEnableCategoriesEditForm] = useState(true);
    const inputCategoriesRef = useRef<SelectInstance>(null);
    const [selected,setSelected] = useState<Option[]>([]);

    useEffect(()=>{
        setOptions(allCategories.data.map((c)=>{return {'value':c.name,'label':c.name}}));
    },[allCategories])

    useEffect(()=>{
        setSelected(categories.map((c)=>{return {'value':c,'label':c}}));
    },[categories])

    const hideCategoriesEditForm = () => {
        setEnableCategoriesEditForm(false);
    }
    const showCategoriesEditForm = () => {
        setEnableCategoriesEditForm(true);
    }

    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        if(inputCategoriesRef.current){
            const values = inputCategoriesRef.current.getValue().map((v)=>(v as Option).value);
            const formData = new FormData();
            formData.append(
                "categories",
                JSON.stringify(values),
            );
            formData.append(
                "field",
                "categories",
            );
            formData.append("type","json");
            onSave(formData,hideCategoriesEditForm);
        }
    }

    return (
        <Container className="business-profile-address mt-5 pt-5">
            <Row>
                <Col>
                    <div className="fs-2 text-center">Categorias</div>
                </Col>
            </Row>
            <Row className="py-5">
                <Col>
                    { !enableCategoriesEditForm && (
                        <div>
                            <div className="fs-2 fw-light d-inline">
                                { categories.map((cat:string, key:number)=>(
                                    <span key={`cat-${key}`} className="badge rounded-pill bg-primary me-2">{cat}</span>
                                )) }
                            </div>
                            <div className="edit-pencil d-inline">
                                <img src={EditPencil} alt="Cambiar nombre" onClick={showCategoriesEditForm} />
                            </div>
                        </div>
                    )}
                    { enableCategoriesEditForm && (
                        <div className="w-100">
                            <Form onSubmit={onSubmit} >
                                <Select name="categories" ref={inputCategoriesRef} options={options} defaultValue={selected} isMulti={true} />
                                <div>
                                    <Button type="submit" className="btn-custom-pink">Guardar</Button>
                                    <Button onClick={hideCategoriesEditForm} className="btn-custom-pink-outline-light">Cancelar</Button>
                                </div>
                            </Form>
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
export default BusinessCategories;