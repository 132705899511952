import React, { useEffect } from 'react';
import {Container} from 'react-bootstrap';
import {BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactPWAInstallProvider from "react-pwa-install";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Directory from "./pages/Directory";
import Events from "./pages/Events";
import Coupons from './pages/Coupons';
import MyCoupons from './pages/MyCoupons';
import AppliedCoupon from './pages/AppliedCoupon';
import CouponStatistics from './pages/CouponStatistics';
import Places from './pages/Places';
import Login from './pages/auth/Login';
import ChangePassword from './pages/auth/ChangePassword';
import Register from './pages/auth/Register';
import Profile from './pages/auth/Profile';
import Confirm from './pages/auth/Confirm';
import BusinessDetail from './pages/BusinessDetail';
import InviteBusiness from './pages/admin/InviteBusiness';
import EditBusiness from './pages/admin/EditBusiness';
import Categories from './pages/admin/Categories';
import Panel from './pages/admin/Panel';
import Businesses from './pages/admin/Businesses';
import ProtectedRoute from './services/ProtectedRoute';
import NotFound from './pages/NotFound';
import AdminEvents from './pages/admin/AdminEvents';
import AdminPlaces from './pages/admin/AdminPlaces';
import Search from './pages/Search';
import RegisterCompleted from './pages/auth/RegisterCompleted';
import Admins from './pages/admin/Admins';
import Comunication from './pages/comunication/Comunication';
import Users from './pages/admin/Users';
import InternNotices from './pages/admin/InternNotices';
import NoticesList from './pages/comunication/NoticesList' ;
import NoticeDetail from './pages/comunication/NoticeDetail';
import CallsList from './pages/comunication/CallsList';
import InternalEvents from './pages/comunication/InternalEvents';

import { useDispatch } from 'react-redux';
import { getEvents } from './redux/eventsSlice';
import { getPlaces } from './redux/placesSlice';
import { getCoupons } from './redux/couponsSlice';
import { getCategories } from './redux/categoriesSlice';
import { getBusinesses } from './redux/businessesSlice';
import { getNotices } from './redux/noticesSlice';

import './App.scss';

function App() {
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(getEvents());
    dispatch(getPlaces());
    dispatch(getCoupons());
    dispatch(getCategories());
    dispatch(getBusinesses());
    dispatch(getNotices());
  });

  return (
    <div className="App">
      <ReactPWAInstallProvider enableLogging>
        <Header />
      </ReactPWAInstallProvider>
      <Container fluid className="p-0">
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/directorio">
              <Directory />
            </Route>
            <Route exact path="/directorio/:slug">
              <Directory />
            </Route>
            <Route exact path="/comercio/:slug">
              <BusinessDetail />
            </Route>
            <Route exact path="/confirmacion/:token">
              <Confirm />
            </Route>
            <Route exact path="/contrasena/:token">
              <ChangePassword />
            </Route>
            <Route exact path="/eventos">
              <Events></Events>
            </Route>
            <Route exact path="/buscar">
              <Search />
            </Route>
            <Route exact path="/lugares">
              <Places />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/registrar">
              <Register />
            </Route>
            <Route exact path="/registro-completo">
              <RegisterCompleted />
            </Route>
            <Route exact path="/cupones">
              <Coupons />
            </Route>
            <ProtectedRoute exact path="/perfil">
              <Profile />
            </ProtectedRoute>
            <ProtectedRoute exect path="/mis-cupones">
              <MyCoupons />
            </ProtectedRoute>
            <Route exact path="/cupon-aplicado">
              <AppliedCoupon />
            </Route>
            <ProtectedRoute exact path="/estadisticas">
              <CouponStatistics></CouponStatistics>
            </ProtectedRoute>
            <ProtectedRoute setRole={"negocio"} exact path="/perfil/Comunicacion">
              <Comunication />
            </ProtectedRoute>
            <ProtectedRoute setRole={"negocio"} exact path="/perfil/Comunicacion/convocatorias">
              <CallsList />
            </ProtectedRoute>
            <ProtectedRoute setRole={"negocio"} exact path="/perfil/Comunicacion/EventosInternos">
              <InternalEvents />
            </ProtectedRoute>
            <ProtectedRoute setRole={"negocio"} exact path="/perfil/Comunicacion/avisos">
              <NoticesList/>
            </ProtectedRoute>
            <ProtectedRoute exact path="/perfil/Comunicacion/avisos/:id">
              <NoticeDetail/>
            </ProtectedRoute>
            <ProtectedRoute setRole={[1]} path="/admin/directorio/nuevo">
              <InviteBusiness />
            </ProtectedRoute>
            <ProtectedRoute setRole={[1]} path="/admin/directorio/editar/:id">
              <EditBusiness />
            </ProtectedRoute>
            <ProtectedRoute setRole={[1]} exact path="/admin/directorio">
              <Businesses />
            </ProtectedRoute>
            <ProtectedRoute setRole={[1]} exact path="/admin/categorias">
              <Categories />
            </ProtectedRoute>
            <ProtectedRoute setRole={[1]} exact path="/admin/eventos">
              <AdminEvents />
            </ProtectedRoute>
            <ProtectedRoute setRole={[1]} exact path="/admin/lugares">
              <AdminPlaces />
            </ProtectedRoute>
            <ProtectedRoute setRole={[1]} exact path="/admin/administradores">
              <Admins />
            </ProtectedRoute>
            <ProtectedRoute setRole={[1]} exact path="/admin/usuarios">
              <Users />
            </ProtectedRoute>
            <ProtectedRoute setRole={[1]} exact path="/admin/avisos">
              <InternNotices />
            </ProtectedRoute>
            <ProtectedRoute setRole={[1]} exact path="/admin">
              <Panel />
            </ProtectedRoute>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Router>
      </Container>
      <Footer></Footer>
    </div>
  );
}

export default App;
