
export const logout = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
    }
export const login = (user:any) => {
    if(user.token){
        localStorage.token = user.token;
        localStorage.user = JSON.stringify(user);
    } else {
        window.location.href = "/";
    }
}