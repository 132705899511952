import { useState } from 'react';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import EditNoticeForm from '../../components/admin/EditNoticeForm';
import NewNotice from '../../components/admin/NewNotice';
import { RootState } from '../../redux/store';
import '../../styles/page.scss';
import Swal from 'sweetalert2';
import { request } from '../../services/Request';
import { Notice, ResponseResult } from '../../types';
import { getNotices } from '../../redux/noticesSlice';
import { useDispatch } from 'react-redux';

const InternNotices = () => {

    const dispatch = useDispatch();
    const notices = useSelector((state: RootState) => state.notices);
    const [showNewForm,setShowNewForm] = useState(false);
    const [showEditForm,setShowEditForm] = useState(false);
    const [noticeToEdit,setNoticeToEdit] = useState<Notice | null>(null);
    const toggleNewForm = () => {
        setShowNewForm(!showEditForm);
    }
    const toggleEditForm = () => {
        setShowEditForm(!showEditForm);
    }
    const hideNewForm = () => {
        setShowNewForm(false);
    }
    const hideEditForm = () => {
        setShowEditForm(false);
    }
    const deleteNotice = (_id:string) => {
        Swal.fire({
            title: '¿Estas seguro?',
            text: "No podras revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
          }).then((result) => {
            if (result.isConfirmed) {
                request(`/api/notices/${_id}`,{
                    method: 'DELETE'
                }).then((result:ResponseResult)=>{
                    if(result.data){
                        dispatch(getNotices());
                        Swal.fire(
                            'Eliminado!',
                            'El aviso ha sido eliminada',
                            'success'
                        )
                    }else{
                        Swal.fire("Avisos", "No se pudo eliminar el aviso", "error");
                    }
                });
            }
          })
    }
    return (
        <Container fluid className="admin-categories page">
            <div className="header">
                <div className="overlay"></div>
                <div className="title">
                    <Row>
                        <Col xs={12}>
                            <h1>
                                Avisos
                            </h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Container className="content-center">
                <Row className="justify-content-md-center py-5">
                    <Col md={10} xs={12}>
                        {showNewForm && (
                            <NewNotice onClose={hideNewForm} />
                        )}
                        {showEditForm && noticeToEdit && (
                            <EditNoticeForm onClose={hideEditForm} notice={noticeToEdit} />
                        )}
                        {!showNewForm && (
                            <Button className="btn-custom-pink-outline-light px-5" onClick={toggleNewForm}>Crear aviso</Button>
                        )}
                    </Col>
                </Row>
                <Row className="justify-content-md-center py-5">
                    <Col md={10} xs={12}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Título</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                { notices.loaded && notices.data.map((item:Notice, key:number)=>(
                                    <tr key={key}>
                                        <td>{ item.title }</td>
                                        <td>
                                            <Button
                                                onClick={()=>{hideNewForm();toggleEditForm();setNoticeToEdit(item);}}
                                                className="btn-custom-pink-outline-light px-5"
                                                >
                                                    Editar
                                            </Button>
                                            <Button
                                                onClick={()=>{deleteNotice(item._id)}}
                                                className="btn-custom-pink px-5 ms-2"
                                                >
                                                    Eliminar
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
export default InternNotices;
