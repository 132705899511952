import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { request } from '../../services/Request';
import { ResponseResult } from '../../types';

interface FormData {
    email: string,
}

interface Props {
    onClose: ()=>void;
    onSuccess: ()=>void;
}

function NewAdmin(props: Props) {
    const { onClose, onSuccess } = props;

    const initialForm : FormData = {
            email: '',
        }
    const [formData,setFormData] = useState<FormData>(initialForm)

    const onCancel = () => {
        setFormData(initialForm);
        onClose();
    }

    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();

        request('/api/auth/admins',{
            method: 'POST',
            body: JSON.stringify(formData),
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                setFormData(initialForm);
                onSuccess();
                Swal.fire("Administradores", "El registro se ha guardado correctamente",'success');
                onClose();
            }else{
                Swal.fire("Administradores", "Error al crear registro",'error');
            }
        })
    }


    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const data : any = formData;
        data[e.target.name] = e.target.value;
        setFormData(data);
    }

    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col sm="2">Nuevo</Col>
            </Row>
            <Form.Group as={Row} className="mb-3 py-1 " controlId="formPlaintextTitle">
                <Form.Label column sm="2" className="text-end">
                    Email
                </Form.Label>
                <Col sm="10">
                    <Form.Control type="email" name="email" placeholder="" required onChange={onChange} />
                </Col>
            </Form.Group>
            <Row>
                <Col>
                    <Form.Group className="mb-3 py-0" controlId="formbutton">
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit"
                                className="btn-custom-pink btn-clock my-2">
                                Guardar
                            </Button>
                            <Button
                                className="btn-custom-blue ms-5 my-2"
                                onClick={onCancel}
                                >
                                Cancelar
                            </Button>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    )
}

export default NewAdmin;