import React, {useState} from 'react';
import { Carousel } from 'react-bootstrap';

function HeaderCarousel(){

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex:number, e:any) => {
        setIndex(selectedIndex);
    };

    const header_images = [
        "https://s3.wasabisys.com/acocenprod/header-min.jpg",
        "https://s3.wasabisys.com/acocenprod/andador%20teatro%20morelos-min.JPG",
        "https://s3.wasabisys.com/acocenprod/CASA%20CULTURA%20ARTES.jpeg",
        "https://s3.wasabisys.com/acocenprod/EL%20ENCIERRO.jpeg"
    ];

    return (
        <div className="header">
            <Carousel activeIndex={index} onSelect={handleSelect}>
                {
                    header_images.map((image:string,key:number)=>(
                        <Carousel.Item key={`mini-${key}`}>
                            <div className="image" style={{backgroundImage: `url(${image})`}}></div>
                        </Carousel.Item>
                    ))
                }
            </Carousel>
            <div className="overlay"></div>
            <div className="title">
                <p>Le damos la bienvenida al</p>
                <h1 className="display-2">Centro Comercial y Turístico <br/>más Grande de Aguascalientes</h1>
                <a href="/directorio" className="btn btn-custom-pink">
                    Conócenos
                </a>
            </div>
        </div>
    )
}

export default HeaderCarousel;